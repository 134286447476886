import { Box, Text, Image, forwardRef } from "@chakra-ui/react";
import { CategoryType } from "../../../HomePage/api/types";

type CategoryProps = CategoryType & {
  isActive: boolean;
  handleClick: (id: string) => void;
};

export const Category = forwardRef<CategoryProps, 'div'>(({
  id,
  name,
  backgroundImageUrl,
  colorCode,
  path,
  isActive,
  handleClick,
}, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="flex-end"
      transition={"all 0.3s"}
      _hover={{
        transform: "translate(-0.3rem, -0.3rem)",
        boxShadow: "0.3rem 0.3rem  rgba(0,0,0,1)",
      }}
      transform={isActive ? "translate(-0.3rem, -0.3rem)" : ""}
      boxShadow={isActive ? "0.3rem 0.3rem  rgba(0,0,0,1)" : ""}
      onClick={() => handleClick(id)}
      cursor="pointer"
      overflow="hidden"
      position="relative"
      borderRadius="0.75rem"
      px={{ base: "1rem", sm: "0.65rem", md: "0.8rem", xl: "1rem" }}
      paddingBottom={{
        base: "1rem",
        sm: "0.65rem",
        md: "1rem",
      }}
      paddingTop={{ base: "2.75rem", md: "3rem", xl: "3.75rem" }}
      backgroundColor={colorCode}
      maxHeight={{base:'5rem', sm:'4rem', md:'5rem', xl:'6.25rem'}}
      minWidth={{base:'10rem', sm:'6.25rem', xl:'10.6rem'}}
    >
      <Text
        zIndex={10}
        sx={{wordWrap:'normal !important'}}
        maxWidth={{base:'100%', sm:'75%' }}
        fontWeight={500}
        color="white"
        size={{ base: "md", sm:'xs', md: "sm", xl: "md" }}
        variant="primary"
      >
        {name}
      </Text>
      <Image
        height="92%"
        maxWidth="55%"
        objectFit="cover"
        bottom="0"
        right={{base:'-10%', lg:'-5%' ,xl:'-10%'}}
        position="absolute"
        src={backgroundImageUrl}
      />
    </Box>
  );
})
