import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Center,
  Button,
  Stack,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { usePublishersBooks } from "../../features/BookDetailsPage/api/client";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import defaultLogo from "../../assets/Default-publisher.png";
import { SearchIcon } from "../../ui/icons/SearchIcon";
import BookList from "../../features/SelectedCategoryPage/components/BookList/BookList";
import { BookType } from "../../features/BookDetailsPage/api/types";
import notFoundImage from "../../assets/Vovchok.png";
import { wordExchanger } from "../../utilities/wordExchanger";
import { usePublisher } from "../../features/PublisherPage/api/client";
interface PublisherPageProps {}

const PublisherPage: FunctionComponent<PublisherPageProps> = () => {
  const { name } = useParams();

  const { data: publisher, isLoading, error } = usePublisher(name??'');
  const searchParameter = useRef<HTMLInputElement | null>(null);
  const [filteredBookList, setFilteredBookList] = useState<BookType[]>([]);
  const { data: bookList } = usePublishersBooks(name ?? "");

  useEffect(() => {
    setFilteredBookList(bookList?.books ?? []);
  }, [bookList]);

  const searchBooks = () => {
    const filteredBookList =
      searchParameter.current !== null
        ? bookList?.books?.filter((book) => {
            return book.name
              .toLowerCase()
              .includes(searchParameter.current!.value.toLocaleLowerCase());
          })
        : bookList?.books;

    setFilteredBookList(filteredBookList ?? []);
  };

  const handleChange = (e: any) => {
    if (e.target?.value === "") {
      setFilteredBookList(bookList?.books ?? []);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchBooks();
    }
  };

  return (
    <Box
      paddingTop={{
        base: "0rem",
        sm: "1.25rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
    >
      <Box
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
      >
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { title: "Видавництва", path: "/publishers" },
            { title: name ?? "" },
          ]}
        />
      </Box>

      <Flex
        marginBottom={{ base: "2.75rem", md: "3rem", xl: "3.75rem" }}
        marginTop={{
          base: "1rem",
          md: "0.75rem",
          xl: "1.25rem",
          "2xl": "1.5rem",
        }}
        flexDirection={{ base: "column", sm: "row" }}
        rowGap="1rem"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="grey"
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
        py={{ base: "1rem", sm: "0.75rem", md: "1.75rem", xl: "2.5rem" }}
        width="100%"
        borderRadius={{ base: "1rem", md: "2rem" }}
      >
        <Box>
          <HStack spacing={{ base: "1rem", md: "2rem" }}>
            <Image
              width={{ base: "7.5rem", sm: "5rem", md: "7.5rem", xl: "10rem" }}
              borderRadius="50%"
              objectFit="cover"
              objectPosition="center"
              sx={{ aspectRatio: "1" }}
              fallbackSrc={defaultLogo}
              src={publisher?.logoUrl}
            />
            <Box>
              <Text
                textAlign={{ base: "center", sm: "start" }}
                lineHeight={{ base: "1.5rem", md: "3.25rem" }}
                fontSize={{
                  base: "1.75rem",
                  sm: "1.5rem",
                  md: "1,75rem",
                  xl: "2.25rem",
                }}
                fontFamily="GaretHeavy"
                fontWeight={850}
                variant="primary"
                marginBottom="0.25rem"
                wordBreak='break-word'
              >
                {name}
              </Text>
              <Text
                textAlign={{ base: "center", sm: "start" }}
                variant="secondary"
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                {bookList?.books.length}{" "}
                {wordExchanger(bookList?.books.length ?? 0)}
              </Text>
            </Box>
          </HStack>
        </Box>
        <InputGroup
          width={{ base: "100%", sm: "13.5rem", xl: "18.75rem" }}
          position="relative"
          borderRadius={{
            base: "0.5rem",
            sm: "0.35rem",
            xl: "0.5rem",
          }}
        >
          <Input
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            ref={searchParameter}
            border="0.0625rem solid transparent"
            outline="none !important"
            _focus={{
              border: "0.0625rem solid black",
              outline: "none !important",
              boxShadow: "none",
            }}
            _hover={{ border: "0.0625rem solid grey" }}
            size={{ base: "mobile", sm: "xs", md: "sm", xl: "md" }}
            background="white"
            placeholder="Пошук у видавництві"
            borderRadius="inherit !important"
          />
          <InputRightElement
            display="flex"
            justifyContent="flex-end"
            right={{ base: "0.3rem", sm: "0.25rem" }}
            top="50%"
            transform="translateY(-50%)"
            h={{
              base: "2.5rem",
              sm: "1.65rem",
              xl: "2.5rem",
            }}
            w={{ base: "13.55%", sm: "18.6%" }}
            maxW={{ base: "13.55%", sm: "20%" }}
          >
            <Button
              onClick={searchBooks}
              _hover={{ backgroundColor: "orange" }}
              _active={{ filter: "brightness(150%)" }}
              background="black"
              minWidth="100%"
              maxWidth="100% !important"
              maxH={{
                base: "2.5rem",
                sm: "1.75rem",
                md: "2rem",
                xl: "2.5rem",
              }}
              height="100%"
              borderRadius={{
                base: "0.5rem",
                sm: "0.25rem",
                md: "0.35rem",
                xl: "0.5rem",
              }}
            >
              <Center>
                <Flex
                  width={{
                    base: "1rem",
                    sm: "0.75rem",
                    lg: "0.875rem",
                    "2xl": "1.25rem",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SearchIcon fill="white" />
                </Flex>
              </Center>
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>
      <Box
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
      >
        {filteredBookList.length !== 0 ? (
          filteredBookList.length !== bookList?.books.length &&
          searchParameter.current &&
          searchParameter.current.value !== "" && (
            <Box
              marginBottom={{
                base: "0.75rem",
                sm: "1rem",
                xl: "1.5rem",
                "2xl": "2rem",
              }}
            >
              <Text
                size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
                marginBottom="0.25rem"
              >{`"${searchParameter.current.value}"`}</Text>
              <Text
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                variant="secondary"
              >
                {filteredBookList.length}{" "}
                {filteredBookList.length === 1
                  ? "результат"
                  : filteredBookList.length > 0 && filteredBookList.length < 5
                  ? "результати"
                  : "результатів"}
              </Text>
            </Box>
          )
        ) : searchParameter.current && searchParameter?.current.value !== "" ? (
          <Stack
            textAlign="center"
            fontSize={{
              base: "1rem",
              sm: "0.75rem",
              md: "1rem",
              xl: "1.25rem",
            }}
            alignItems="center"
          >
            <Image
              marginBottom={{ base: "1rem", xl: "2rem" }}
              width={{ base: "12rem", xl: "15.25rem" }}
              src={notFoundImage}
            />
            <Text fontSize="inherit" textAlign="inherit">
              За вашим запитом <b>{`“${searchParameter?.current!.value}”`}</b>{" "}
              нічого не знайдено.
            </Text>
            <Text textAlign="inherit" fontSize="inherit">
              Спробуйте ще.
            </Text>
          </Stack>
        ) : (
          <Center height={{ base: "50vh", sm: "fit-content" }}>
            <Text
              opacity={0.5}
              size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
              fontWeight={500}
            >
              На даний момент книжок немає
            </Text>
          </Center>
        )}
        <BookList books={filteredBookList} />
      </Box>
    </Box>
  );
};

export default PublisherPage;
