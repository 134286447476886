import { Button } from "@chakra-ui/button";
import { Flex, Text } from "@chakra-ui/layout";
import { FunctionComponent } from "react";

interface LetterPaginationProps {
  letters: string[];
  activeLetter: string;
  handleClick: (letter: string) => void;
}

const LetterPagination: FunctionComponent<LetterPaginationProps> = ({
  letters,
  activeLetter,
  handleClick,
}) => {
  return (
    <Flex gap='0.875rem' flexWrap='wrap'>
      {letters.map((letter) => {
        return (
          <Button
            _hover={{
              border: '0.0625rem solid orange',
              transition:"all 0.3s"
            }}
            _active={{}}
            padding="0rem !important"
            minWidth={{
              base: "2rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2.5rem",
            }}
            maxWidth={{
              base: "2rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2.5rem",
            }}
            height={{
              base: "2rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2.5rem",
            }}
            borderRadius="50%"
            backgroundColor={letter === activeLetter ? "orange" : "transparent"}
            key={letter}
            onClick={() => handleClick(letter)}
          >
            <Text
              fontWeight={500}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              color={letter === activeLetter ? "white" : "black"}
              lineHeight="1.25rem"
            >
              {letter}
            </Text>
          </Button>
        );
      })}
    </Flex>
  );
};

export default LetterPagination;
