import { Box, Text, Image, forwardRef } from "@chakra-ui/react";
import { CategoryType } from "../../api/types";
import { useNavigate } from "react-router-dom";
export const Category = forwardRef<CategoryType, 'div'>(({
  id,
  name,
  backgroundImageUrl,
  colorCode,
  path,
}, ref) => {
  const navigate = useNavigate();

  return (
    <Box
      scrollSnapAlign='start'
      ref={ref}
      display='flex'
      alignItems='flex-end'
      transition={"all 0.3s"}
      _hover={{
        base: "",
        sm: {
          transform: "translate(-0.3rem, -0.3rem)",
          boxShadow: "0.3rem 0.3rem  rgba(0,0,0,1)",
        },
      }}
      cursor="pointer"
      onClick={() =>
        navigate(`/categories/${name}`)
      }
      overflow="hidden"
      position="relative"
      borderRadius="0.75rem"
      px={{ base: "1rem", sm: "0.65rem", md: "0.8rem", xl: "1rem" }}
      paddingBottom={{
        base: "1rem",
        sm: "0.65rem",
        md: "0.8rem",
        xl: "1rem",
      }}
      paddingTop={{ base: "2.75rem", sm: "2.75rem", md: "3rem", xl: "3.75rem" }}
      minWidth={{
        base:'10rem',
        sm: "9rem",
        xl: "11.25rem",
      }}
      width={{
        base: "47.7%",
        sm: "40%",
        md: "15.25%",
        xl: "13%",
        "2xl": "11.5%",
      }}
      backgroundColor={colorCode}
      maxHeight={{base:'5rem', sm:'4rem', md:'5rem', xl:'6.25rem'}}
    >
      <Text 
        sx={{wordWrap:'normal !important'}}
        fontWeight={500}
        maxWidth={{base:'100%', sm:'75%'}}
        lineHeight="1.25rem"
        color="white"
        size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
        variant="primary"
      >
        {name}
      </Text>
      <Image
        height="92%"
        objectFit='cover'
        bottom="0rem"
        right='-10%'
        position="absolute"
        src={backgroundImageUrl}
      />
    </Box>
  );
})
