import { Flex, Image, Text, forwardRef } from "@chakra-ui/react";
import { PublisherProps } from "../../api/types";
import logo from '../../../../assets/Default-publisher.png'
export const PublisherSwitchItem = forwardRef<PublisherProps, 'div'>(({
  id,
  name,
  logoUrl,
  handleClick,
  isActive,
}, ref) => {
  return (
    <Flex
      ref={ref}
      _hover={!isActive ? {backgroundColor: 'rgba(255, 85, 0, 0.3)'}: {}}
      minWidth='fit-content'
      borderRadius={{ base: "0.75rem", sm:'0.35rem', md: "0.5rem", xl: "0.75rem" }}
      backgroundColor={isActive ? "orange" : "transparent"}
      padding="0.25rem"
      paddingRight={{ base: "0.5rem", md: "1rem", xl: "1.5rem" }}
      gap={{ base: "0.5rem", sm:'0.35rem', md: "0.5rem", xl: "0.75rem" }}
      alignItems="center"
      cursor="pointer"
      transition="all 0.2s ease"
      onClick={() => handleClick(id)}
    >
      <Image
        borderRadius="50%"
        objectFit="cover"
        objectPosition="center"
        width={{ base: "2.5rem", sm:'1.5rem', md: "2rem", xl: "3rem" }}
        sx={{aspectRatio: '1'}}
        src={logoUrl}
        fallbackSrc={logo}
      />
      <Text
        fontWeight={400}
        size={{ base: "caption", sm:'xs', md: "sm", xl: "md" }}
        color={isActive ? "white" : "blackAlpha.800"}
      >
        {name}
      </Text>
    </Flex>
  );
})
