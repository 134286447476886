import { useState, useEffect } from "react";
import { auth } from "./firebase";
const useActualUser = () => {
  const [user, setUser] = useState<any>();
  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return { user };
};

export default useActualUser;
