import { OrderedList } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import NavigationItem from "../NavigationItem/NavigationItem";

interface NavigationPanelProps {
  activeBoxIndex?: number;
  handleNavClick(index: number): void
}

const NavigationPanel: FunctionComponent<NavigationPanelProps> = ({
  activeBoxIndex,
  handleNavClick
}) => {


  return (
    <OrderedList
      overflowY="auto"
      top={{
        base: "1.5rem",
        "2xl": "6.25rem",
      }}
      paddingBottom="0.5rem"
      paddingRight="0.25rem"
      start={0}
      height="80vh"
      css={{
        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 0.25rem rgba(0,0,0,0.3)",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar": {
          width: "0.25rem",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#FF5500",
          borderRadius: "1.25rem",
        },
      }}
      position="sticky"
      left="0"
      width="19.5%"
      flexShrink={0}
      spacing="2rem"
      margin={0}
      stylePosition="inside"
      textAlign="left"
    >
      <NavigationItem
        index={0}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 0}
        title="Вступ"
      />
      <NavigationItem
        index={1}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 1}
        title="Опис Сервісу"
      />
      <NavigationItem
        index={2}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 2}
        title="Порядок реєстрації"
      />
      <NavigationItem
        index={3}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 3}
        title="Ваші гарантії"
      />
      <NavigationItem
        index={4}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 4}
        title="Строк"
      />
      <NavigationItem
        index={5}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 5}
        title="Передплата"
      />
      <NavigationItem
        index={6}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 6}
        title="Ввічливість та норми поведінки"
      />
      <NavigationItem
        index={7}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 7}
        title="Територія роботи"
      />
      <NavigationItem
        index={8}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 8}
        title="Інтелектуальна власність"
      />
      <NavigationItem
        index={9}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 9}
        title="Незаконне або заборонне використання"
      />
      <NavigationItem
        index={10}
        handleClick={handleNavClick}
        isActive={activeBoxIndex === 10}
        title="Політика конфіденційності"
      />
    </OrderedList>
  );
};

export default NavigationPanel;
