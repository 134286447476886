import { useEffect } from "react";
import { BookType } from "../../api/types";
import {
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useBreakpoint,
} from "@chakra-ui/react";
import { Book } from "../../../HomePage/components/Book/Book";
import { NavLink, useLocation } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";
import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";
import { useBookList } from "../../api/client";
import ErrorMessage from "../../../../ui/components/ErrorMessage/ErrorMessage";

interface BookListProps {
  name: string;
  type: "authors" | "publishers";
}

const BookList: React.FC<BookListProps> = ({ name, type }) => {
  const { data: bookList, isLoading, error } = useBookList(type)(name, 1, 5);

  const breakpoint = useBreakpoint();
  const location = useLocation();

  const booksDisplayed = useBreakpointValue({ base: 3, md: 4, xl: 5 });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location]);

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Box width="100%">
      <Stack
        marginBottom={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "flex-start", sm: "center" }}
      >
        <HStack
          maxWidth="100%"
          flexWrap="wrap"
          lineHeight="1.75rem"
          spacing={{ base: "0.35rem", md: "0.5rem", xl: "0.75rem" }}
        >
          <Text
            fontFamily="Garet"
            fontSize={{
              base: "1.25rem",
              sm: "1rem",
              xl: "1.5rem",
            }}
          >
            {type === "publishers" ? "Видавництво" : "Автор"}
          </Text>
          <Text
            maxWidth="100%"
            fontFamily="GaretHeavy"
            fontSize={{
              base: "1.25rem",
              sm: "1rem",
              xl: "1.5rem",
            }}
            fontWeight={850}
          >{`[${name}]`}</Text>
        </HStack>
        <NavLink to={`/${type}/${name}`}>
          <Flex
            alignItems="center"
            gap={{ base: "0.5rem", md: "0.75rem", xl: "1rem" }}
          >
            <Text
              lineHeight="1rem"
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              variant="link"
            >
              {type === "publishers" ? "До видавництва" : "Всі книжки автора"}
            </Text>
            <Box boxSize={{ base: "1rem", sm: "0.5rem", md: "1rem" }}>
              <BiChevronRight size="100%" color="#FF5500" />
            </Box>
          </Flex>
        </NavLink>
      </Stack>
      {breakpoint !== "base" ? (
        <HStack spacing="1.94%">
          {bookList?.books.slice(0, booksDisplayed).map((book: BookType) => {
            return (
              <Book
                width={{ base: "62.5%", sm: "32%", md: "24%", xl: "18.4%" }}
                key={book.id}
                name={book.name}
                authors={[book.authors[0]]}
                cover={book.cover}
                id={book.id}
              />
            );
          })}
        </HStack>
      ) : (
        <Carousel gap="1.25rem" hasButtons={false}>
          {bookList?.books.map((book: BookType) => (
            <Book
              key={book.id}
              minWidth="62.5%"
              name={book.name}
              authors={[book.authors[0]]}
              cover={book.cover}
              id={book.id}
            />
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default BookList;
