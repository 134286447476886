import { Box, Center, Stack, Text } from "@chakra-ui/react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import GenreList from "../../features/SelectedCategoryPage/components/GenreList/GenreList";
import BookList from "../../features/SelectedCategoryPage/components/BookList/BookList";
import { Paginate } from "../../ui/components/Pagination/Pagination";
import ErrorMessage from "../../ui/components/ErrorMessage/ErrorMessage";
import { useBooksByGenreAndCategory } from "../../features/SelectedCategoryPage/api/client";
import Lottie from "lottie-react";
import loader from "../../assets/loader.json";
import { wordExchanger } from "../../utilities/wordExchanger";
const SelectedCategoryPage: React.FC = () => {
  const { name } = useParams();
  const [searchParams] = useSearchParams();
  const genre = searchParams.get("genre");
  const navigate = useNavigate();

  const [activeGenre, setActiveGenre] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data: bookList,
    isLoading,
    error,
  } = useBooksByGenreAndCategory(name ?? "", currentPage, 15, genre);

  const pageCount = Math.ceil(
    (bookList?.paging?.totalElements ?? 1) /
      parseInt(bookList?.paging?.limit ?? "1")
  );

  const handleChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  if (error) {
    return <ErrorMessage message="Щось пішло не так!" />;
  }

  const pickGenre = (id: string, genre: string) => {
    setActiveGenre(id);
    navigate(`?genre=${genre}`);
  };

  return (
    <Box
      paddingTop={{ base: "0rem", sm: "1.25rem", md: "1.75rem", xl: "2.5rem" }}
      px={{
        base: "0.5rem",
        md: "1rem",
        xl: "2rem",
        "2xl": "4.75rem",
      }}
    >
      <Breadcrumb
        breadcrumbItems={[
          { title: "Головна", path: "/" },
          { title: "Категорії", path: "/categories" },
          { title: name ?? "" },
        ]}
      />
      <Box
        marginBottom={{
          base: "2rem",
          sm: "1.25rem",
          md: "2rem",
          "2xl": "3rem",
        }}
        marginTop={{ base: "1rem", sm: "0.75rem", md: "1.75rem", xl: "2.5rem" }}
      >
        <Stack
          flexDirection={{ base: "column", sm: "row" }}
          alignItems={{ base: "flex-start", sm: "flex-end" }}
          gap={{ base: "0.5rem", sm: "1rem", xl: "2rem" }}
          justifyContent="space-between"
          marginBottom={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
        >
          <Text
            fontWeight={{ base: 600, sm: 400 }}
            as="h1"
            lineHeight="1"
            fontSize={{
              base: "1.75rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            variant="primary"
          >
            {name}
          </Text>
          <Text
            marginTop="0rem !important"
            fontWeight={400}
            lineHeight="1rem"
            variant="secondary"
            size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
          >
            {bookList?.books &&
              `${bookList?.books.length} ${wordExchanger(
                bookList?.books.length
              )}`}
          </Text>
        </Stack>
        <Box marginRight={{ base: "-1rem", sm: "0rem" }}>
          <GenreList handleClick={pickGenre} activeGenre={activeGenre} />
        </Box>
      </Box>
      {isLoading ? (
        <Center
          flexDirection="column"
          minWidth={{ base: "100%", md: "55.35% !important" }}
          height={{ base: "50vh", sm: "fit-content" }}
          marginBottom="2rem"
        >
          <Lottie
            marginWidth={0}
            style={{ width: "15%" }}
            animationData={loader}
          />
          <Text variant="link" size={{ base: "sm", xl: "md" }} fontWeight={300}>
            Хвильку зачекайте...
          </Text>
        </Center>
      ) : bookList?.books.length === 0 ? (
        <Center paddingTop="3rem" height={{ base: "50vh", sm: "fit-content" }}>
          <Text
            opacity={0.5}
            size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
            fontWeight={500}
          >
            На даний момент книжок немає
          </Text>
        </Center>
      ) : (
        <BookList books={bookList?.books ?? []} />
      )}
      <Paginate
        pageCount={pageCount ?? 1}
        previousPage={previousPage}
        nextPage={nextPage}
        currentPage={currentPage}
        onChange={handleChange}
      />
    </Box>
  );
};

export default SelectedCategoryPage;
