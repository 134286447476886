import { FunctionComponent } from "react";
import { usePublishers } from "../../../HomePage/api/client";
import { SimpleGrid } from "@chakra-ui/layout";
import Publisher from "../Publisher/Publisher";
import { PublisherType } from "../../../HomePage/api/types";

interface PublisherListProps {
    publishers: PublisherType[] | undefined;
}

const PublisherList: FunctionComponent<PublisherListProps> = ({publishers}) => {
  return (
    <SimpleGrid
      rowGap={{ base: "2rem", md: "3rem", xl: "4.5rem" }}
      gap='3.7%'
      columns={{ base: 2, sm: 4, md: 5, xl:6, "2xl": 7 }}
    >
      {publishers?.map((publisher) => {
        return <Publisher key={publisher.id} {...publisher} />;
      })}
    </SimpleGrid>
  );
};

export default PublisherList;
