import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import React from "react";
import { IoMdClose } from "react-icons/io";
interface FilterItemProps {
  filter: any;
  removeFilter(key: string, name: string): void
}

const FilterItem: FunctionComponent<FilterItemProps> = ({ filter, removeFilter }) => {
  return (
    <Flex
      border="0.0625rem solid #FF5500"
      borderRadius="0.5rem"
      px={{base:'0.5rem',lg:"1rem"}}
      py={{base:'0.3rem',lg:"0.625rem"}}
      alignItems='center'
      gap='1rem'
    >
      <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
        {filter.name}
      </Text>
      <IconButton
        onClick={() => removeFilter(filter.key, filter.name)}
        _active={{}}
        _hover={{}}
        background='transparent'
        height="auto"
        size='auto'
        aria-label="close"
        icon={<IoMdClose color='black' opacity='0.5' width="0.625rem" />}
      />
    </Flex>
  );
};

export default FilterItem;
