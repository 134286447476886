import {
  Box,
  Flex,
  StackDivider,
  Text,
  VStack,
  Center,
} from "@chakra-ui/react";
import { Suspense, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";
import { PublisherSwitchItem } from "../PublisherSwitchItem/PublisherSwitchItem";
import { MobilePublisherBookList } from "../PublishersBookList/MobilePublishersBookList";
import { NavLink } from "react-router-dom";
import { usePublishers } from "../../api/client";
import Loader from "../../../../ui/components/Loader/Loader";

export const MobilePublishersSection: React.FC = () => {
  const { data: publisherList, isLoading, error } = usePublishers();
  const [activeTab, setActiveTab] = useState<string>(
    publisherList?.publishingHouses[0].id ?? ""
  );

  const pickTab = (id: string) => {
    setActiveTab(id);
  };

  return (
    <VStack
      px="0.75rem"
      paddingTop="1.25rem"
      paddingBottom="0.25rem"
      spacing="1.5rem"
      background="grey"
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
      divider={
        <StackDivider height="0.0675rem" borderColor="rgba(0, 0, 0, 0.1)" />
      }
    >
      <Box width="100%">
        <Flex
          px="0.5rem"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="1.25rem"
        >
          <Text
            as="h1"
            lineHeight="1"
            fontWeight={400}
            fontSize="1.25rem"
            variant="primary"
          >
            Видавництва
          </Text>
          <NavLink to="/publishers">
            <Flex alignItems="center" gap="0.5rem">
              <Text fontWeight={500} size="caption" variant="link">
                Всі
              </Text>
              <Box boxSize="1rem">
                <BiChevronRight size="100%" color="#FF5500" />
              </Box>
            </Flex>
          </NavLink>
        </Flex>
        <Carousel hasButtons={false} gap="3.1%">
          {publisherList?.publishingHouses.map((publisher) => {
            return (
              <PublisherSwitchItem
                handleClick={pickTab}
                isActive={activeTab === publisher.id}
                key={publisher.id}
                {...publisher}
              />
            );
          })}
        </Carousel>
      </Box>
      <Suspense
        fallback={
          <Loader/>
        }
      >
        <MobilePublisherBookList
          publisherId={activeTab}
          publisherName={
            publisherList?.publishingHouses.find(
              (publisher) => publisher.id === activeTab
            )?.name ?? ""
          }
        />
      </Suspense>
    </VStack>
  );
};
