import {
  Container,
  VStack,
  Image,
  Text,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Center,
  HStack,
  AspectRatio,
  Show,
  Link,
  Stack,
} from "@chakra-ui/react";
import { SearchIcon } from "../../icons/SearchIcon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../icons/ArrowIcon";
import googlePlayBadge from "../../../assets/google-play-badge.png";
import appleStorebadge from "../../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import logo from "../../../assets/Logo-white.svg";
import { useState, useEffect } from "react";
import useActualUser from "../../../utilities/useActualUser";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";
export const MobileFooter: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const location = useLocation();
  const { user } = useActualUser();

    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [location]);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchBooks();
    }
  };
  const searchBooks = () => {
    search !== "" && navigate(`/catalogue?searchParameter=${search}`);
  };

  return (
    <Container
      margin="0rem !important"
      padding="0rem"
      minWidth="100vw"
      bg="black"
    >
      <Container px="1.25rem" py="1.25rem" as="footer">
        <Flex
          gap="0.25rem"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          rowGap="2rem"
        >
          <VStack spacing="0.25rem">
            <Image width="8.375rem" src={logo} alt="Logo" />
            <Text
              whiteSpace="nowrap"
              fontSize="0.75rem"
              color="white"
              opacity={0.5}
            >
              &copy; 2023 All rights reserved.
            </Text>
          </VStack>

          <AspectRatio
            flexShrink={0}
            width={{
              base: "2.5rem",
              sm: "1.2rem",
              md: "1.6rem",
              lg: "2rem",
              xl: "3rem",
            }}
            ratio={1}
          >
            <Button
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
              maxWidth="100%"
              minWidth="100%"
              _hover={{ backgroundColor: "orange" }}
              padding="0rem !important"
              borderRadius="0.5rem"
              borderColor="white"
              borderWidth="0.125rem"
              bg="black"
            >
              <Center>
                <ArrowIcon height="1.125rem" />
              </Center>
            </Button>
          </AspectRatio>
          <HStack
            minWidth="fit-content"
            width="100%"
            justifyContent="space-between"
          >
            <Link
              maxWidth="51.25%"
              isExternal
              href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
            >
              <Image width="100%" height="auto" src={googlePlayBadge} />
            </Link>
            <Link
              width="46.25%"
              isExternal
              href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
            >
              <Image
                flexShrink={1}
                width="100%"
                height="auto"
                src={appleStorebadge}
              />
            </Link>
          </HStack>
          <Stack lineHeight='1.125rem' width="100%" alignItems="flex-start" gap="1.5rem">
            <HStack
              paddingRight="1rem"
              width="100%"
              justifyContent="space-between"
            >
              <NavLink to="/about">
                <Text
                  whiteSpace="nowrap"
                  size="caption"
                  textAlign="left"
                  variant="base"
                >
                  Про читанку
                </Text>
              </NavLink>
              <NavLink to="/terms-and-conditions">
                <Text
                  whiteSpace="nowrap"
                  size="caption"
                  textAlign="left"
                  variant="base"
                >
                  Умови використання
                </Text>
              </NavLink>
            </HStack>
            <HStack
              margin="0rem !important"
              paddingRight="1rem"
              width="100%"
              justifyContent="space-between"
            >
              {user !== null ? (
                <NavLink to="/profile">
                  <Text
                    whiteSpace="nowrap"
                    size="caption"
                    textAlign="left"
                    variant="base"
                  >
                    Мій кабінет
                  </Text>
                </NavLink>
              ) : (
                <NavLink to="/login">
                  <Text
                    whiteSpace="nowrap"
                    size="caption"
                    textAlign="left"
                    variant="base"
                  >
                    Реєстрація / Вхід
                  </Text>
                </NavLink>
              )}
              <NavLink to="/returns">
                <Text
                  whiteSpace="nowrap"
                  size="caption"
                  textAlign="left"
                  variant="base"
                >
                  Повернення
                </Text>
              </NavLink>
            </HStack>

            <Link
              style={{ margin: 0 }}
              as="a"
              isExternal
              href="https://publisher.chytanka.com/login"
            >
              <HStack spacing="0.25rem">
                <Text
                  whiteSpace="nowrap"
                  size="caption"
                  textAlign="left"
                  variant="base"
                >
                  Кабінет видавця
                </Text>
                <ExternalLinkIcon fill="white" width="0.875rem" />
              </HStack>
            </Link>
          </Stack>
          <InputGroup width="100%" position="relative" borderRadius="0.5rem">
            <Input
              spellCheck={false}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              size="mobile"
              background="white"
              placeholder="Пошук книг"
              borderRadius="inherit !important"
            />
            <InputRightElement
              display="flex"
              justifyContent="flex-end"
              right="0.3rem"
              top="50%"
              transform="translateY(-50%)"
              h="2.5rem"
              w="16.25%"
              maxW="16.25%"
            >
              <Button
                onClick={searchBooks}
                _hover={{ backgroundColor: "orange" }}
                _active={{ filter: "brightness(150%)" }}
                background="black"
                minWidth="100%"
                maxWidth="100% !important"
                maxH="2.5rem"
                height="100%"
                borderRadius="0.5rem"
              >
                <Center>
                  <Flex
                    width="1rem"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SearchIcon fill="white" />
                  </Flex>
                </Center>
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
      </Container>
    </Container>
  );
};
