import {
  VStack,
  Image,
  Text,
  AspectRatio,
  StackProps,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BookPreviewType } from "../../api/types";
import { useNavigate } from "react-router";
import defaultCover from "../../../../assets/default-book-cover.jpg";
interface BookProps extends StackProps, BookPreviewType {
  leaveBottomSpace?: boolean;
}

export const Book = forwardRef<BookProps, "div">(
  ({ id, name, authors, cover, leaveBottomSpace, ...styles }, ref) => {
    const navigate = useNavigate();
    const textContentHeight = useBreakpointValue({ base: 'auto', sm:'3rem', md:'5rem', xl:'6.25rem'})
    return (
      <VStack
        scrollSnapAlign="start"
        position='relative'
        ref={ref}
        overflow="visible"
        spacing={{ base: "0.75rem", sm: "5.88%" }}
        cursor="pointer"
        onClick={() => navigate(`/books/${id}`)}
        {...styles}
      >
        <AspectRatio minWidth="100%" ratio={0.666}>
          <Image
            fallbackStrategy="onError"
            fallbackSrc={defaultCover}
            transition="all 0.3s"
            _hover={{
              transform: "scale(1.05)",
              transformOrigin: "center center",
            }}
            src={cover === '' ? 'https://localhost:65536/': cover}
            objectFit="cover"
            objectPosition="center"
          />
        </AspectRatio>
        <VStack
          height={ leaveBottomSpace ? textContentHeight: 'auto'}
          spacing={{
            base: "0.25rem",
            sm: "0.1rem",
            md: "0.125rem",
            xl: "0.25rem",
          }}
        >
          <Text
            textAlign="center"
            fontWeight={500}
            lineHeight={1}
            size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
            variant="primary"
          >
            {name}
          </Text>
          <Text
            textAlign='center'
            lineHeight="1rem"
            fontSize={{
              base: "0.75rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "0.875rem",
            }}
            variant="secondary"
          >
            {authors[0]}
          </Text>
        </VStack>
      </VStack>
    );
  }
);
