import {
  Box,
  HStack,
  Image,
  Show,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useUsersBooks } from "../../api/client";
import { wordExchanger } from "../../../../utilities/wordExchanger";
import { Book } from "../../../HomePage/components/Book/Book";
import noBooksImage from "../../../../assets/Vovchok.png";
import { CatalogueIcon } from "../../../../ui/icons/CatalogueIcon";
import Loader from "../../../../ui/components/Loader/Loader";
import { NavLink } from "react-router-dom";
import useActualUser from "../../../../utilities/useActualUser";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";

const PurchasedBookList: FunctionComponent = () => {
  const { user } = useActualUser();

  const { data: bookList, isLoading, error } = useUsersBooks(user?.uid ?? "");

  return (
    <Stack
      flexGrow={1}
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      height="100%"
    >
      <Show below="sm">
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { path: "/profile", title: "Мій кабінет" },
            { title: "Придбані книги" },
          ]}
        />
      </Show>
      <HStack
        marginTop={{ base: "1rem !important", sm: "0" }}
        marginBottom={{
          base: "1.25rem !important",
          sm: "1rem",
          md: "0.75rem",
          xl: "1.25rem",
          "2xl": "1.5rem",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          fontWeight={600}
          fontSize={{
            base: "1.25rem",
            sm: "1rem",
            xl: "1.5rem",
          }}
        >
          Придбані книги
        </Text>
        {bookList?.length !== 0 && (
          <Text
            lineHeight="1rem"
            textAlign="center"
            size={{ base: "caption", sm: "xs", md: "sm", xl: "caption" }}
            variant="secondary"
          >
            {bookList &&
              `${bookList?.length} 
            ${wordExchanger(bookList?.length ?? 0)}`}
          </Text>
        )}
      </HStack>
      {isLoading ? (
        <Loader width="100%" minHeight='10vh'/>
      ) : bookList?.length !== 0 ? (
        <SimpleGrid
          margin="0 !important"
          columns={{ base: 2, sm: 3, lg: 4 }}
          spacing={{ base: "1rem", md: "1.25rem", lg: "1.5rem", xl: "2rem" }}
          rowGap={{
            base: "2rem",
            md: "2.5rem",
            lg: "3.15rem",
            xl: "4.25rem",
          }}
        >
          {bookList?.map((book) => {
            return <Book key={book.id} {...book} />;
          })}
        </SimpleGrid>
      ) : (
        <Stack
          flexGrow={1}
          margin="0 !important"
          gap={{ base: "1rem", xl: "2rem" }}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <Image width={{ base: "12rem", xl: "15.25rem" }} src={noBooksImage} />
          <Box margin="0 !important">
            <Text
              fontSize={{
                base: "1rem",
                sm: "1rem",
                xl: "1.25rem",
              }}
              textAlign="inherit"
              marginBottom="0.25rem"
            >
              Ви не придбали жодної книги.
            </Text>
            <Text
              textAlign="inherit"
              variant="secondary"
              size={{ base: "caption", sm: "xs", md: "sm", xl: "caption" }}
            >
              Книги, які ви придбаєте, з’являться тут.
            </Text>
          </Box>
          <NavLink style={{ margin: "0" }} to="/catalogue">
            <HStack
              margin="0 !important"
              _hover={{
                backgroundColor: "white",
                color: "black",
                fill: "black",
              }}
              color="white"
              fill="white"
              transition="all 0.3s"
              cursor="pointer"
              backgroundColor="black"
              borderRadius="0.5rem"
              border="0.065rem solid black"
              py={{
                base: "1.25rem",
                sm: "0.5rem",
                md: "0.75rem",
                xl: "1.125rem",
              }}
              px={{
                base: "2.25rem",
                sm: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              spacing="0.75rem"
              alignItems="center"
            >
              <CatalogueIcon
                fill="inherit"
                width={{
                  base: "1rem",
                  sm: "0.75rem",
                  xl: "1rem",
                }}
              />
              <Text
                color="inherit"
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Перейти до каталогу
              </Text>
            </HStack>
          </NavLink>
        </Stack>
      )}
    </Stack>
  );
};

export default PurchasedBookList;
