import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { Book } from "../../../HomePage/components/Book/Book";
import { CategoryType } from "../../api/types";
import { BookPreviewType } from "../../../HomePage/api/types";
import { hexToRgb } from "../../../../utilities/hexToRgb";
import { useBooksByCategory } from "../../api/client";
interface BookListProps {
  activeCategory?: CategoryType;
}

export const BookList: React.FC<BookListProps> = ({ activeCategory }) => {
  const {
    data: bookList,
    isLoading,
    error,
  } = useBooksByCategory(activeCategory?.name ?? "", 1, 6);

  const breakpoint = useBreakpoint();

  const background = hexToRgb(activeCategory?.colorCode || "");

  if (error) {
    return (
      <Flex
        alignItems="center"
        width={{ base: "100%", md: "55.35%" }}
        marginRight={{ base: "0.75rem", sm: "0rem" }}
        py={{ base: "1.5rem", xl: "2rem" }}
        borderRadius="1rem"
        backgroundColor="#FF726F"
        justifyContent="center"
      >
        <Text
          fontWeight={500}
          color={"white"}
          size={{ base: "lg", sm: "md", xl: "lg" }}
        >
          Щось пішло не так!
        </Text>
      </Flex>
    );
  }

  return (
    <Box
      marginBottom={{ base: "-3.75rem", sm: "0" }}
      marginLeft={{ base: "-0.75rem", sm: "0rem" }}
      width={{ base: "100vw", sm: "100%", md: "55.35%" }}
      transition="all 0.3s"
    >
      <Box
        height='100%'
        borderRadius={{ base: "0rem", sm: "1rem", md: "1.5rem", xl: "2rem" }}
        px={{ base: "1.25rem", sm: "1rem", md: "2.5rem", "2xl": "4.125rem" }}
        py={{
          base: "1.5rem",
          sm: "0.75rem",
          md: "1.5rem",
          xl: "2rem",
          "2xl": "3.75rem",
        }}
        width="100%"
        backgroundColor={`rgba(${background.r},${background.g},${background.b},0.1)`}
      >
        <Flex
          alignItems="center"
          marginBottom={{ base: "1.25rem", md: "1.5rem", xl: "2rem" }}
          justify="space-between"
        >
          <Text
            as="h1"
            lineHeight="1"
            fontWeight={400}
            fontSize={{
              base: "1.25rem",
              sm: "1rem",
              xl: "1.5rem",
            }}
            variant="primary"
          >
            {activeCategory?.name}
          </Text>
          <NavLink
            to={`/categories/${activeCategory?.name}`}
            state={{ categoryName: activeCategory?.name }}
          >
            <Flex
              alignItems="center"
              gap={{ base: "0.5rem", md: "0.75rem", xl: "1rem" }}
            >
              <Text
                size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
                variant="link"
              >
                {breakpoint === "base" ? "Перейти" : "Перейти до категорії"}
              </Text>
              <Box boxSize={{ base: "1rem", sm: "0.5rem", md: "1rem" }}>
                <BiChevronRight size="100%" color="#FF5500" />
              </Box>
            </Flex>
          </NavLink>
        </Flex>
        {bookList?.books.length === 0 ? (
          <Center height={{base:'50vh', sm:'fit-content'}}>
            <Text
              opacity={0.5}
              size={{ base: "md", sm:'xs', md: "md", xl: "lg" }}
              fontWeight={500}
            >
              На даний момент книжок немає
            </Text>
          </Center>
        ) : (
          <SimpleGrid
            paddingBottom={{ base: "1.5rem", sm: "0rem" }}
            columns={{ base: 2, sm: 3 }}
            spacing={{ base: "1rem", md: "0.75rem", xl: "1.5rem" }}
            rowGap={{ base: "1rem", md: "1.5rem", xl: "3rem" }}
          >
            {bookList?.books.slice(0, 6).map((book: BookPreviewType) => {
              return (
                <Book
                  key={book.id}
                  id={book.id}
                  authors={book.authors}
                  name={book.name}
                  cover={book.cover}
                />
              );
            })}
          </SimpleGrid>
        )}
      </Box>
      {breakpoint === "base" && !isLoading && bookList?.books.length !== 0 && (
        <Box py="2.125rem" width="100vw">
          <NavLink
            to={`/categories/${activeCategory?.name}`}
            state={{ categoryName: activeCategory?.name }}
          >
            <Flex
              alignItems="center"
              justifyContent='center'
              gap={{ base: "0.5rem", md: "0.75rem", xl: "1rem" }}
            >
              <Text fontWeight={500} size="caption" variant="link">
                Перейти до категорії
              </Text>
              <Box boxSize="1rem">
                <BiChevronRight size="100%" color="#FF5500" />
              </Box>
            </Flex>
          </NavLink>
        </Box>
      )}
    </Box>
  );
};
