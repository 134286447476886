import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";

interface EyeIconProps extends StyleProps {}

const EyeIcon: React.FC<EyeIconProps> = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0619857 8.346C1.77299 13 5.67499 16 9.99999 16C14.325 16 18.227 13 19.938 8.346C20.021 8.12279 20.021 7.87721 19.938 7.654C18.227 3 14.325 0 9.99999 0C5.67499 0 1.77299 3 0.0619857 7.654C-0.0209874 7.87721 -0.0209874 8.12279 0.0619857 8.346V8.346ZM9.99999 2C13.373 2 16.451 4.343 17.929 8C16.451 11.657 13.373 14 9.99999 14C6.62699 14 3.54899 11.657 2.07099 8C3.54899 4.343 6.62699 2 9.99999 2ZM9.99999 12C10.7911 12 11.5645 11.7654 12.2223 11.3259C12.8801 10.8864 13.3928 10.2616 13.6955 9.53073C13.9983 8.79983 14.0775 7.99556 13.9231 7.21964C13.7688 6.44371 13.3878 5.73098 12.8284 5.17157C12.269 4.61216 11.5563 4.2312 10.7803 4.07686C10.0044 3.92252 9.20016 4.00173 8.46925 4.30448C7.73835 4.60723 7.11363 5.11992 6.67411 5.77772C6.23458 6.43552 5.99999 7.20887 5.99999 8C5.99999 9.06087 6.42141 10.0783 7.17156 10.8284C7.9217 11.5786 8.93912 12 9.99999 12ZM9.99999 6C10.3955 6 10.7822 6.1173 11.1111 6.33706C11.44 6.55682 11.6964 6.86918 11.8477 7.23463C11.9991 7.60009 12.0387 8.00222 11.9616 8.39018C11.8844 8.77814 11.6939 9.13451 11.4142 9.41421C11.1345 9.69392 10.7781 9.8844 10.3902 9.96157C10.0022 10.0387 9.60007 9.99913 9.23462 9.84776C8.86917 9.69638 8.55681 9.44004 8.33705 9.11114C8.11728 8.78224 7.99999 8.39556 7.99999 8C7.99999 7.46957 8.2107 6.96086 8.58577 6.58579C8.96085 6.21071 9.46955 6 9.99999 6V6Z"
        fill="black"
      />
    </Icon>
  );
};

export default EyeIcon;
