import { useMutation, useQuery } from "react-query";
import api from "../../../services/api/api";
import { BookPreviewType } from "../../HomePage/api/types";
import { UserDataResponseType } from "./types";

export const useUsersBooks = (userId: string) => {
  return useQuery(
    ["usersBooks", userId],
    () => {
      return api.get<string, Array<BookPreviewType>>(`/usersBooks?userId=${userId}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: userId !== "",
      retry: 3,
      retryDelay: 200
    }
  );
};

export const useUsersData = (userId: string) => {
  return useQuery(
    ["usersData", userId],
    () => {
      return api.get<string, UserDataResponseType>(`/userData?userId=${userId}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: userId !== "",
    }
  );
};

export const useUpdateUserData = () => {
  return useMutation((params: { userId: string, userData: UserDataResponseType }) => {
    return api.post<string, boolean>("/updateUserData", params);
  }, {
    retry: 2,
    retryDelay: 200
  });
} 
