import { FunctionComponent, useState } from "react";
import { SearchIcon } from "../../../../ui/icons/SearchIcon";
import { Button, Center, Flex, Input, InputGroup, InputRightElement, StyleProps } from "@chakra-ui/react";

interface SearchBarProps extends StyleProps {
    setState: (text: string) => void,
    placeholder: string,
    color?: string,
    initialValue?: string
}

const SearchBar: FunctionComponent<SearchBarProps> = ({ setState, placeholder, color, initialValue }) => {
    
    const [search, setSearch] = useState<string>(initialValue??'');

    const handleKeyDown = (e: any) => {
      if (e.key === "Enter") {
        setState(search);
      }
    };

    const handleChange = (e: any) => {
      if (e.target?.value !== "") {
        setSearch(e.target?.value);
        return;
      }
      setSearch('');
      setState('');
    };
  

    return (
    <InputGroup
      width={{ base: "100%", sm: "12rem", md:'13.5rem', xl:'15rem' ,'2xl': "18.75rem" }}
      position="relative"
      borderRadius={{
        base: "0.5rem",
        sm: "0.25rem",
        md: "0.35rem",
        xl: "0.5rem",
      }}
    >
      <Input
        spellCheck={false}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={search}
        border="0.0625rem solid transparent"
        outline="none !important"
        _focus={{
          border: "0.0625rem solid black",
          outline: "none !important",
          boxShadow: "none",
        }}
        _hover={{ border: "0.0625rem solid grey" }}
        size={{ base: "mobile", sm: "xs", md: "sm", xl: "md" }}
        background={color??"white"}
        placeholder={placeholder}
        borderRadius="inherit !important"
      />
      <InputRightElement
        display="flex"
        justifyContent="flex-end"
        right={{ base: "0.3rem", sm: "0.25rem" }}
        top="50%"
        transform="translateY(-50%)"
        h={{
          base: "2.5rem",
          sm: "1.65rem",
          xl: "2.5rem",
        }}
        w={{ base: "16.3%", sm: "18.6%" }}
        maxW={{ base: "16.3%", sm: "20%" }}
      >
        <Button
          onClick={() => setState(search)}
          _hover={{ backgroundColor: "orange" }}
          _active={{ filter: "brightness(150%)" }}
          background="black"
          minWidth="100%"
          maxWidth="100% !important"
          maxH={{
            base: "2.5rem",
            sm: "2rem",
            xl: "2.5rem",
          }}
          height="100%"
          borderRadius={{
            base: "0.5rem",
            sm: "0.25rem",
            md: "0.35rem",
            xl: "0.5rem",
          }}
        >
          <Center>
            <Flex
              width={{
                base: "1rem",
                sm: "0.75rem",
                lg: "0.875rem",
                "2xl": "1.25rem",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <SearchIcon fill="white" />
            </Flex>
          </Center>
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchBar;
