import { Box, SimpleGrid, Text, Flex } from "@chakra-ui/layout";
import { FunctionComponent } from "react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import { useParams } from "react-router";
import { useBookList } from "../../features/BookDetailsPage/api/client";
import { Book } from "../../features/HomePage/components/Book/Book";
import Loader from "../../ui/components/Loader/Loader";

interface AuthorPageProps {}

const AuthorPage: FunctionComponent<AuthorPageProps> = () => {
  const { name } = useParams();
  const {
    data: bookList,
    isLoading,
    error,
  } = useBookList("authors")(name ?? "");

  return (
    <Box
      paddingTop={{
        base: "0rem",
        sm: "1.25rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      px={{
        base: "0.5rem",
        md: "1rem",
        xl: "2rem",
        "2xl": "4.75rem",
      }}
    >
      <Breadcrumb
        breadcrumbItems={[
          { title: "Головна", path: "/" },
          { title: "Автори" },
          { title: name ?? "" },
        ]}
      />
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        marginTop={{
          base: "0.5rem",
          sm: "1.25rem",
          md: "1.75rem",
          xl: "2rem",
          "2xl": "2.5rem",
        }}
        marginBottom={{
          base: "0.75rem",
          sm: "1rem",
          md: "1.5rem",
          xl: "2rem",
        }}
      >
        <Text
          fontWeight={600}
          fontSize={{
            base: "1rem",
            sm: "1.5rem",
            md: "1,75rem",
            xl: "2.25rem",
          }}
          variant="primary"
        >
          {name}
        </Text>
        {bookList?.books && (
          <Text
            fontWeight={400}
            lineHeight="1rem"
            variant="secondary"
            size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
          >
            {`${bookList?.books.length} ${
              bookList?.books.length === 1 ? "книга" : "книг"
            }`}
          </Text>
        )}
      </Flex>
      {isLoading ? (
        <Loader width="100%" />
      ) : (
        <SimpleGrid
          spacing={{ base: "1rem", md: "1.25rem", lg: "1.5rem", xl: "2rem" }}
          rowGap={{ base: "2rem", md: "2.5rem", lg: "3.15rem", xl: "4.25rem" }}
          columns={{ base: 2, sm: 3, md: 4, xl: 5 }}
        >
          {bookList?.books.map((book) => {
            return (
              <Book
                key={book.id}
                id={book.id}
                cover={book.cover}
                authors={book.authors}
                name={book.name}
              />
            );
          })}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default AuthorPage;
