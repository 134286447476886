import { Box, Center, CenterProps, Text } from "@chakra-ui/react";
import loader from "../../../assets/loader.json";
import Lottie from "lottie-react";

const Loader: React.FC<CenterProps> = (props) => {
  return (
    <Center
      flexDirection="column"
      width={{ base: "100%", md: "55.35% !important" }}
      flexGrow={0}
      {...props}
    >
      <Lottie marginWidth={0} style={{ width: "15%" }} animationData={loader} />
      <Text variant="link" size="sm" fontWeight={300}>
        Хвильку зачекайте...
      </Text>
    </Center>
  );
};

export default Loader;

