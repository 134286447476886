import { FunctionComponent } from "react";
import { PublisherType } from "../../../HomePage/api/types";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import defaultLogo from "../../../../assets/Default-publisher.png";
import { usePublishersBooks, usePublishersBooksCount } from "../../../BookDetailsPage/api/client";
import { NavLink } from "react-router-dom";
import { wordExchanger } from "../../../../utilities/wordExchanger";

interface PublisherProps extends PublisherType {}

const Publisher: FunctionComponent<PublisherProps> = ({ id, logoUrl, name }) => {
  const {
    data: publisherBookList,
    isLoading,
    error,
  } = usePublishersBooks(name);
  const { data: publisherBooksCount } = usePublishersBooksCount(name);

  return (
    <NavLink to={`/publishers/${name}`} state={{ logoUrl: logoUrl}}>
      <VStack>
        <Image
          transition={"all 0.3s"}
          _hover={{
            transform: "translate(-0.3rem, -0.3rem)",
            boxShadow: "0.3rem 0.3rem  rgba(0,0,0,1)",
          }}
          borderRadius="50%"
          objectFit="cover"
          objectPosition="center"
          sx={{ aspectRatio: "1" }}
          width={{ base: "5rem", xl: "7.5rem" }}
          fallbackSrc={defaultLogo}
          src={logoUrl}
        />
        <Box marginTop="1rem">
          <Text
            marginBottom="0.25rem"
            size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            fontWeight={400}
            textAlign="center"
            variant="primary"
          >
            {name}
          </Text>
          <Text
            lineHeight="1rem"
            textAlign="center"
            size={{ base: "caption", sm: "xs", md: "sm", xl: "caption" }}
            variant="secondary"
          >
            {publisherBooksCount && `${publisherBooksCount.booksCount} ${wordExchanger(publisherBooksCount.booksCount)}`}
          </Text>
        </Box>
      </VStack>
    </NavLink>
  );
};

export default Publisher;
