import { Box, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";

interface TermsAndConditionsProps {
  refs: React.MutableRefObject<HTMLDivElement[]>;
}

const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = ({
  refs,
}) => {
  return (
    <Box
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      height="fit-content"
      flexGrow={1}
    >
      <Breadcrumb
        breadcrumbItems={[
          { title: "Головна", path: "/" },
          { title: "Умови використання" },
        ]}
      />
      <Stack
        gap={{ base: "1.5rem", md: "2rem", xl: "3rem" }}
        marginTop={{
          base: "1rem",
          md: "0.75rem",
          xl: "1.25rem",
          "2xl": "1.5rem",
        }}
      >
        <Box ref={(ref: any) => (refs.current[0] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.75rem",
              md: "1rem",
              xl: "1.25rem",
            }}
            fontWeight={600}
            as="h2"
            fontSize={{
              sm: "0.75rem",
              xl: "2.25rem",
            }}
          >
            Умови використання
          </Text>
          <Stack spacing={{ base: "1rem", xl: "1.5rem" }}>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Тут ви можете ознайомитись із умовами роботи додатку «Читанка»
              (далі – Додаток) і зрозуміти правові основи його функціонування.
              Ми рекомендуємо Вам прочитати весь текст.    
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Ці Умови використання Додатку є договором (далі також – Договір)
              між Вами, фізичною особою («Ви» або «Користувач»), і Читанка
              («Платформа») щодо Послуг, як визначено нижче. Використовуючи
              Додаток, ви підтверджуєте, що вам виповнилося 18 років або якщо ви
              є неповнолітньою особою користування Додатком відбуватиметься за
              згоди батьків, ви погоджуєтеся дотримуватися цих Умов використання
              та всіх положень та умов, що містяться і / або згадані в цьому
              документі, або будь-яких додаткових положень та умов, викладених в
              Додатку, і ви підтверджуєте згоду на обробку вашої особистої
              інформації відповідно до цього Договору, яка може знадобитися для
              надання Послуг відповідно до Договору. Якщо ви НЕ згодні з цими
              Умовами використання, вам НЕ слід використовувати Послуги. 
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Цей Договір регулює ваші відносини з Платформою, і використання
              послуг, наданих вам Платформою, як визначено нижче. Реєструючись в
              Додатку Ви приймаєте всі умови, що містяться в цьому Договорі і
              підтверджуєте, що можете користуватися послугами тільки на умовах
              нижче. Будь ласка, уважно прочитайте ці умови перед використанням
              послуг, що надаються Платформою (далі – Послуги). Використання
              Послуг означає, що ви приймаєте всі ці умови. Якщо ви не приймаєте
              ці умови або ви не досягли 18 років, будь ласка не використовуйте
              Послуги.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Ці Умови використання можуть бути змінені Платформою в будь-який
              час. Такі змінені Умови використання вступають в силу з моменту
              повідомлення про зміну.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Платформа залишає за собою право вносити зміни або оновлення в
              контент Додатку (далі – Контент) або його формат в будь-який час
              без попереднього повідомлення. Платформа залишає за собою право
              припинити або обмежити доступ до Додатку або будь-якої його
              частини з будь-якої причини на власний розсуд.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Платформа не гарантує і не дає жодних заяв щодо безпеки Додатку.
              Ви, підтверджуєте розуміння того, що будь-яка відправлена Вами
              інформації може бути перехоплена під час передачі або іншим чином.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Використання Додатку здійснюється на ваш власний розсуді і ризик
              та з врахуванням вашого підтвердження, що ви будете самостійно
              нести відповідальність за будь-які пошкодження вашої
              комп'ютерної/мобільної системи, втрату даних або іншу шкоду.
              Платформа не буде нести відповідальність за будь-які вірус або
              інший подібний програмний код, завантажений на ваш телефон або
              інший пристрій з Додатку або в зв'язку з наданими Послугами. Ніякі
              усні чи письмові поради або інформація, отримані вами в Додатку не
              створюють жодної гарантії, прямо не передбаченої в Умовах
              використання.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Ви розумієте і погоджуєтеся з тим, що, використовуючи Додаток,
              Послуги, що ним надаються, ви можете зіткнутися з контентом, який
              ви можете розцінити як образливий, непристойний або небажаний, і
              що в такому випадку ви використовуєте Додаток на свій власний
              ризик.
            </Text>
            <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
              Ні за яких умов Платформа не несе відповідальності за дії третіх
              осіб в Додатку.
            </Text>
          </Stack>
        </Box>
        <Box ref={(ref: any) => (refs.current[1] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            1. Опис сервісу
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Читанка — це Додаток, який працює на безоплатній та передплатній
            системі доступу до книжок. Зареєстровані користувачі отримують
            доступ для читання книжок і додаткові сервіси (пошук, рецензії,
            коментарі, оцінки і рекомендації), які повинні допомогти у виборі
            цікавої книги або нового учасника і у використанні сервісу. Всі
            книги в Додатку охороняються чинним законодавством України про
            авторське право і не можуть бути завантажені і поширені за межами
            Додатку. Додаток не має на увазі передачу книжок у розпорядження або
            володіння користувачам. Читання книжок може відбуватися лише через
            Додаток для мобільних та інших пристроїв. Ніякі файли книжок
            користувачам для скачування не пропонуються. Можливість копіювання
            тексту книги користувачам не надається. Вся організація особистих
            каталогів книг (полиць) відбувається виключно всередині самого
            сервісу.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[2] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            2. Порядок реєстрації
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Ми пропонуємо нашим користувачам зареєструватися для того, щоб вони
            могли залишити свої рецензії або оцінки, коментарі, отримати
            рекомендації по книжкам і авторам, а також для оформлення передплати
            та користування послугами Додатку. Для реєстрації необхідна
            електронна пошта або  обліковий запис  Google чи Apple.
            <br />
            Реєстрація, використання і оплата сервісу означають, що користувач
            ознайомився з цим Договором і приймає його умови. Доступ до Послуг
            може бути надано тільки фізичним особам. Зверніть увагу, що один 
            обліковий запис може використовуватися тільки однією фізичною особою
            не більше ніж на трьох пристроях. В разі, якщо кількість пристроїв,
            що використовують Ваш обліковий запис більша ніж три, Платформа має
            право припинити використання Додатку на всіх пристроях. Таким чином,
            ваш обліковий запис в Додатку є персональним, і ці Умови
            використання забороняють надавати доступ до вашого профілю іншим
            особам.  Якщо ви спробуєте порушити ці Умови використання, Платформа
            має право заблокувати або видалити ваш особистий обліковий запис без
            будь-яких пояснень.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[3] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            3. Ваші гарантії
          </Text>
          <Box>
            <Text
              lineHeight="2rem"
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              Ви гарантуєте, що: 
            </Text>
            <UnorderedList
              fontSize={{
                base: "1rem",
                sm: "0.75rem",
                xl: "1rem",
              }}
              spacing="0.5rem"
              stylePosition="inside"
            >
              <ListItem>
                вся інформація, яка надається вами для користування Додатком є
                істинною і точною;
              </ListItem>
              <ListItem>
                ви будете дотримуватися всіх умов, вказаних в цьому Договорі та
                здійснювати всі обов’язки відповідно до цих Умов використання
                Додатку;
              </ListItem>
              <ListItem>вам виповнилось 18 років.</ListItem>
            </UnorderedList>
          </Box>
        </Box>
        <Box ref={(ref: any) => (refs.current[4] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            4. Строк
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Строк дії цього Договору починається з моменту початку використання
            вами Додатку і триватиме безстроково, якщо інше не буде вирішено
            Платформою. Платформа залишає за собою право в будь-який час
            змінити, призупинити або припинити надання Послуг. Дія цього
            Договору припиняється автоматично, якщо Ви не дотримуєтеся будь-яких
            обмежень або інших вимог, описаних в цьому документі. При будь-якому
            припиненні або закінченні строку дії цього Договору Ви повинні
            негайно припинити використання Послуг.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[5] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            5. Передплата
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Передплата — попередня оплата користувачем доступу до обраної книги.
            Після оплати придбана книжка додається на полицю користувача і
            користувач одержує доступ до самого тексту книжки.
            <br />
            Передплата проводиться через платіжні системи Apple Pay (для iOS
            додатку) та Google Play (для Android додатку). При виборі форми
            оплати ми спрямуємо користувача на сервіс Apple Pay (для iOS
            додатку) та Google Play (для Android додатку) для введення
            реквізитів через захищене з'єднання. Це гарантує, що дані
            користувача потраплять тільки до платіжної системи. В Додатку
            платіжні дані не зберігаються. Послуга (доступ до книги) активується
            негайно по факту оплати.
            <br />
            Крім книжок, які Ви можете отримати лише після попередньої оплати,
            Платформа пропонує вам доступ до безкоштовних книг. Для того, щоб
            почати читати книги, які не потребують попередньої оплати, необхідно
            додати таку книгу на полицю користувача.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[6] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            6. Ввічливість та норми поведінки
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Ми будемо вдячні користувачам, якщо вони будуть вести себе на в
            Додатку ввічливо і культурно, не зачіпаючи і не ображаючи інших
            читачів, у яких можуть бути інші точки зору або смаки.
            <br /> Ми залишаємо за собою право модерації та редагування рецензій
            та інших коментарів, якщо будемо вважати їх образливими або занадто
            агресивними. Ненормативна лексика та заклики до будь-яких
            протиправних дій також будуть видалені. Користувачів, які вирішать
            проігнорувати правила поведінки, буде заблоковано. Також ми з сумом
            заблокуємо користувачів, які будуть зловживати доступом до сервісу,
            про що ми зможемо зрозуміти за статистикою використання сервісу.
            Гроші, сплачені за передплату, в цьому випадку ми не повернемо.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[7] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            7. Територія роботи
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            На підставі ліцензій на твори Платформа має право надавати сервіс і
            передплату на території України та інших держав усього Світу на які
            поширюється територія дії ліцензії, яку отримала Платформа від
            правовласника.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[8] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            8. Інтелектуальна власність
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Авторські права і всі інші майнові права на Додаток і / або Контент
            належать Платформі і / або власникам відповідних ліцензій. Якщо інше
            спеціально не передбачено в цьому документі, всі права на Послуги та
            / або Контент, ненадані явно в цьому документі, захищені. Ви
            погоджуєтесь не копіювати, не публікувати, не надавати для
            завантаження, передавати, змінювати, здавати в оренду, позичати,
            продавати, переуступати, поширювати, ліцензувати права,  створювати
            похідні роботи на основі Додатку та або Контенту.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[9] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            9. Незаконне або заборонне використання
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Ви не можете використовувати Додаток для будь-яких цілей, які є
            незаконними, забороненими цими Умовами використання, або яким-небудь
            чином втручаються або намагаються перешкодити належній роботі
            Додатку. Ви не можете використовувати цей Додаток будь-яким чином,
            який може пошкодити, відключити, перевантажити або завдати шкоди
            Додатку або перешкодити використанню Додатку третіми особами.  Ви
            погоджуєтеся з тим, що не будете  використовувати будь-яке стороннє
            програмне забезпечення, яке перехоплює, «видобуває» або іншим чином
            збирає інформацію з Додатку або  через нього. Ви не можете
            отримувати або намагатися отримати будь-які матеріали або інформацію
            будь-якими способами, які Платформа не надає користувачам Додатку.
          </Text>
        </Box>
        <Box ref={(ref: any) => (refs.current[10] = ref)}>
          <Text
            marginBottom={{
              base: "1rem",
              sm: "0.5rem",
              md: "0.75rem",
              xl: "1rem",
            }}
            fontWeight={600}
            as="h3"
            size={{ base: "md", sm: "sm", md: "md", xl: "lg" }}
          >
            10. Політика конфіденційності
          </Text>
          <Stack
            gap={{
              base: "1rem",
              sm: "0.75rem",
              md: "1rem",
              xl: "1.25rem",
            }}
          >
            <Text
              fontWeight={500}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              БУДЬ ЛАСКА, УВАЖНО ПРОЧИТАЙТЕ ЦЕЙ РОЗДІЛ. ПРОДОВЖУЮЧИ ВИКОРИСТАННЯ
              ДОДАТКУ ТА ПОСЛУГ, ВИ, ПІДТВЕРДЖУЄТЕ, ЩО ВИ (A) ВІКОМ ВІД 18
              РОКІВ, І (Б) ВИЗНАЛИ ЦЮ ПОЛІТИКУ КОНФІДЕНЦІЙНОСТІ ЯК НЕВІД’ЄМНУ
              ЧАСТИНУ УМОВ ВИКОРИСТАННЯ.
            </Text>
            <Text
              fontWeight={500}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              КЛЮЧОВА ІНФОРМАЦІЯ
            </Text>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Хто збирає дані?
              </Text>
              <Box>
                <Text
                  marginBottom="0.5rem"
                  size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                >
                  Ваші дані збирають:
                </Text>
                <UnorderedList
                  fontSize={{
                    base: "1rem",
                    sm: "0.75rem",
                    xl: "1rem",
                  }}
                  paddingLeft="0.5rem"
                  stylePosition="outside"
                >
                  <ListItem>Читанка</ListItem>
                </UnorderedList>
              </Box>
            </Box>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Які дані збираються?
              </Text>

              <UnorderedList
                spacing="0.5rem"
                paddingLeft="0.5rem"
                stylePosition="outside"
                fontSize={{
                  base: "1rem",
                  sm: "0.75rem",
                  xl: "1rem",
                }}
              >
                <ListItem>Логін;</ListItem>
                <ListItem>
                  Адреси електронної пошти та дані облікових записів в Apple та
                  Google;
                </ListItem>
                <ListItem lineHeight="2rem">
                  Дані профілю, які ви хочете надати (зовсім необов'язково):
                  <UnorderedList
                    fontSize={{
                      base: "1rem",
                      sm: "0.75rem",
                      xl: "1rem",
                    }}
                    paddingLeft="1rem"
                  >
                    <ListItem>ім'я</ListItem>
                    <ListItem>вік</ListItem>
                    <ListItem>стать</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>Обрані вами цитати, коментарі;</ListItem>
                <ListItem>
                  Інформація, автоматично отримана з вашого пристрою: тип
                  пристрою, вхід в додаток і т. д.;
                </ListItem>
                <ListItem>Логін; Статистику про використання Додатку;</ListItem>
                <ListItem>
                  Оцінки користувача та рецензії та коментарі до книжок;
                </ListItem>
                <ListItem>
                  Анонімні статистичні дані про читання книжок;
                </ListItem>
                <ListItem>
                  Інформація про дати, суми і канали транзакцій користувачів.;
                </ListItem>
              </UnorderedList>
              <Text
                marginTop="0.5rem"
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                При здійсненні передоплати користувачам може знадобитися вказати
                свої особисті дані, якщо цього потребує платіжна система.
                Додаток ці дані не отримує, не зберігає і не використовує.
              </Text>
            </Box>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Яка правова основа для обробки даних?
              </Text>
              <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
                Ми обробляємо ваші дані на підставі цього Договору між вами і
                нами та чинного законодавства про захист персональних даних, в
                тій мірі, в якій це потрібно для виконання Договору.
              </Text>
            </Box>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Чи будуть дані передані третім особам / передані за кордон?
              </Text>
              <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
                Ні.
              </Text>
            </Box>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Як буде використовуватися інформація?
              </Text>
              <UnorderedList
                spacing="0.5rem"
                paddingLeft="0.5rem"
                stylePosition="outside"
                fontSize={{
                  base: "1rem",
                  sm: "0.75rem",
                  xl: "1rem",
                }}
              >
                <ListItem>
                  Ми будемо використовувати дані тільки для того, щоб Послуги
                  Додатку, що надаються Вам відповідно до Договору, були
                  максимально приємними:
                </ListItem>
                <ListItem>
                  Щоб надати вам інформацію, пов'язану з сервісом Додатку;
                </ListItem>
                <ListItem>
                  Щоб зв'язатися з вами тільки у справах, пов'язаних з наданням
                  Послуг;
                </ListItem>
                <ListItem>Покращувати і розвивати Додаток;</ListItem>
                <ListItem>
                  Надати індивідуальні рекомендації по книгам;
                </ListItem>
                <ListItem>
                  Для захисту наших інтересів відповідно до Договору та
                  дотримання вимог законодавства;
                </ListItem>
                <ListItem>Створювати статистичні звіти.</ListItem>
              </UnorderedList>
            </Box>
            <Box>
              <Text
                marginBottom="0.5rem"
                fontWeight={500}
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              >
                Які права є у наших користувачів?
              </Text>
              <Box>
                <Text
                  lineHeight="1rem"
                  marginBottom="0.5rem"
                  size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                >
                  Що стосується ваших особистих даних, ви маєте право:
                </Text>
                <UnorderedList
                  paddingLeft="0.5rem"
                  stylePosition="outside"
                  fontSize={{
                    base: "1rem",
                    sm: "0.75rem",
                    xl: "1rem",
                  }}
                  spacing="0.5rem"
                >
                  Що стосується ваших особистих даних, ви маєте право: запросити
                  доступ до всіх ваших даних, і ми надамо його протягом 30 днів
                  (ми можемо попросити вас пройти додаткову ідентифікацію, щоб
                  переконатися, що ніхто, крім вас, не може отримати його);
                  видалити свої дані.
                  <ListItem>
                    запросити доступ до всіх ваших даних, і ми надамо його
                    протягом 30 днів (ми можемо попросити вас пройти додаткову
                    ідентифікацію, щоб переконатися, що ніхто, крім вас, не може
                    отримати його);
                  </ListItem>
                  <ListItem>
                    оновити або виправити свої дані (ви можете зробити це в
                    налаштуваннях Додатку);
                  </ListItem>
                  <ListItem>заперечувати проти обробки ваших даних;</ListItem>
                  <ListItem>видалити свої дані</ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;
