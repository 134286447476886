import { Text, Box, Flex } from "@chakra-ui/react";
import { PublisherSwitchItem } from "../PublisherSwitchItem/PublisherSwitchItem";
import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { PublisherType } from "../../api/types";
interface PublisherTabsProps {
  publishers: PublisherType[];
  activeTab: string;
  pickTab: (id: string) => void;
}
export const PublishersSwitch: React.FC<PublisherTabsProps> = ({
  publishers,
  activeTab,
  pickTab,
}) => {
  
  return (
    <Box flexShrink={1} py="1.25rem">
      <Text
        as="h1"
        lineHeight="1"
        fontWeight={400}
        size={{ base: "sm", md: "md", xl: "lg" }}
        variant="primary"
        marginBottom={{ base: "1rem", md: "1.35rem", xl: "1.75rem" }}
      >
        Видавництва
      </Text>
      <Flex flexDirection="column" alignItems="flex-start" gap="1rem">
        {publishers.slice(0, 6).map((publisher) => {
          return (
            <PublisherSwitchItem
              key={publisher.id}
              isActive={activeTab === publisher.id}
              handleClick={pickTab}
              {...publisher}
            />
          );
        })}
      </Flex>
      <NavLink to={`/publishers`}>
        <Flex
          marginTop={{ base: "1.25rem", md: "1.75rem", xl: "2.25rem" }}
          alignItems="center"
          gap={{ base: "0.2rem", md: "0.5rem", xl: "1rem" }}
        >
          <Text
            fontWeight={500}
            size={{ base: "xs", md: "sm", xl: "md" }}
            variant="link"
          >
            Всі видавництва
          </Text>
          <Box boxSize={{ base: "0.5rem", md: "1rem" }}>
            <BiChevronRight size="100%" color="#FF5500" />
          </Box>
        </Flex>
      </NavLink>
    </Box>
  );
};
