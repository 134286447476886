import { Flex, Show, useBreakpoint } from "@chakra-ui/react";
import Genre from "../Genre/Genre";
import { useGenres } from "../../api/client";
import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";

interface GenreListProps {
  activeGenre: string;
  handleClick: (id: string, genre: string) => void;
}

const GenreList: React.FC<GenreListProps> = ({ activeGenre, handleClick }) => {
  const colors = ["#F55071", "#BFA5A7", "#C2A875", "#00AA65"];
  const breakpoint = useBreakpoint();
  const { data: genreList, isLoading, error } = useGenres();

  return (
    <>
        <Show above="sm">
      <Flex
        justifyContent="stretch"
        marginBottom={{ base: "1rem", md: "1.5rem", xl: "3rem" }}
        flexWrap="wrap"
        gap={{ base: "0.5rem", sm: "0.75rem", md: "1rem", xl: "1.25rem" }}
      >
        {genreList?.genres.map((genre, index) => {
          return (
            <Genre
              handleClick={handleClick}
              isActive={activeGenre === genre.id}
              key={genre.id}
              {...genre}
              colorCode={colors[index % colors.length]}
            />
          );
        })}
      </Flex>
    </Show>
    <Show below='sm'>
      <Carousel hasButtons={false} gap='0.5rem'>
      {genreList?.genres.map((genre, index) => {
          return (
            <Genre
              handleClick={handleClick}
              isActive={activeGenre === genre.id}
              key={genre.id}
              {...genre}
              colorCode={colors[index % colors.length]}
            />
          );
        })}
      </Carousel>
    </Show>
    </>

  );
};

export default GenreList;
