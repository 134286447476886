import {
  Flex,
  Hide,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import SidePanel from "../../features/ProfilePage/components/SidePanel/SidePanel";
import { Outlet } from "react-router-dom";
const ProfilePage: FunctionComponent = () => {

  return (
    <Flex
      px={{
        base: "0.5rem",
        md: "1rem",
        xl: "2rem",
        "2xl": "4.75rem",
      }}
      minWidth="22%"
      gap="4.6%"
    >
      <Hide below='sm'>
        <SidePanel />
      </Hide>
      <Outlet />
    </Flex>
  );
};

export default ProfilePage;
