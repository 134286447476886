import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";
interface GoogleSignInIconProps extends StyleProps {}

export const GoogleSignInIcon: React.FC<GoogleSignInIconProps> = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2330_6338)">
        <path
          d="M23.766 12.2764C23.766 11.4606 23.6999 10.6405 23.5588 9.83801H12.24V14.459H18.7217C18.4528 15.9493 17.5885 17.2677 16.323 18.1055V21.1039H20.19C22.4609 19.0138 23.766 15.9273 23.766 12.2764Z"
          fill="#4285F4"
        />
        <path
          d="M12.24 24.0008C15.4764 24.0008 18.2058 22.9382 20.1944 21.1039L16.3274 18.1055C15.2516 18.8375 13.8626 19.252 12.2444 19.252C9.11377 19.252 6.45935 17.1399 5.50693 14.3003H1.51649V17.3912C3.5536 21.4434 7.70278 24.0008 12.24 24.0008Z"
          fill="#34A853"
        />
        <path
          d="M5.50254 14.3003C4.99987 12.81 4.99987 11.1962 5.50254 9.70581V6.61487H1.5165C-0.185503 10.0056 -0.185503 14.0005 1.5165 17.3913L5.50254 14.3003Z"
          fill="#FBBC04"
        />
        <path
          d="M12.24 4.74966C13.9508 4.7232 15.6043 5.36697 16.8433 6.54867L20.2694 3.12262C18.1 1.0855 15.2207 -0.034466 12.24 0.000808666C7.70278 0.000808666 3.5536 2.55822 1.51649 6.61481L5.50252 9.70575C6.45053 6.86173 9.10936 4.74966 12.24 4.74966Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_6338">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
