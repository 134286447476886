import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import useActualUser from "../../../utilities/useActualUser";
interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  children,
}) => {

  const { user } = useActualUser();

  const isLoggedIn = user !== null;

  if (!isLoggedIn) {
    return (
      <>
        <Navigate to="/login" replace />
      </>
    );
  }

  return user !== undefined && children;
};
export default ProtectedRoute;
