import { Box, Flex, Show } from "@chakra-ui/react";
import { FunctionComponent, useState, useRef, useEffect } from "react";
import NavigationPanel from "../../features/TermsAndConditionsPage/components/NavigationPanel/NavigationPanel";
import TermsAndConditions from "../../features/TermsAndConditionsPage/components/TermsAndConditions/TermsAndConditions";

interface TermsAndConditionsPageProps {}

const TermsAndConditionsPage: FunctionComponent<
  TermsAndConditionsPageProps
> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const boxRefs = useRef<HTMLDivElement[]>([]);
  const [activeBoxIndex, setActiveBoxIndex] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      let minDistance = Infinity;
      let newActiveBoxIndex: number = -1;

      boxRefs.current.forEach((ref, index) => {
        if (ref) {
          const { top } = ref.getBoundingClientRect();
          const distance = Math.abs(top);
          if (top < windowHeight && top >= 0 && distance < minDistance) {
            minDistance = distance;
            newActiveBoxIndex = index;
          }
        }
      });

      newActiveBoxIndex !== -1 && setActiveBoxIndex(newActiveBoxIndex);
    };

    containerRef?.current &&
      containerRef.current.addEventListener("scroll", handleScroll);
    return () =>
      containerRef?.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavClick = (index: number) => {
    if (containerRef.current) {
      containerRef.current.scrollTop = boxRefs.current[index].offsetTop;
    }

    setActiveBoxIndex(index);
  };

  return (
    <Box overflow="hidden">
      <Flex
        ref={containerRef}
        height="100vh"
        position="relative"
        overflowY="scroll"
        scrollBehavior="smooth"
        sx={{
          "&::-webkit-scrollbar": {
            width: "0px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
        gap="6%"
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
      >
        <Show above="md">
          <NavigationPanel
            handleNavClick={handleNavClick}
            activeBoxIndex={activeBoxIndex}
          />
        </Show>

        <TermsAndConditions refs={boxRefs} />
      </Flex>
    </Box>
  );
};

export default TermsAndConditionsPage;
