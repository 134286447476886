import { Center, HStack, StackDivider, Text } from "@chakra-ui/react";
import { PublishersSwitch } from "../PublishersSwitch/PublishersSwitch";
import { PublisherBookList } from "../PublishersBookList/PublishersBookList";
import { Suspense, useState } from "react";
import { usePublishers } from "../../api/client";
import Loader from "../../../../ui/components/Loader/Loader";

export const PublishersSection: React.FC = () => {
  const { data: publisherList, isLoading, error } = usePublishers();
  // active tab will be equal to publisher's id, which is a string
  const [activeTab, setActiveTab] = useState<string>(
    publisherList?.publishingHouses[0].id ?? ""
  );

  const pickTab = (id: string) => {
    setActiveTab(id);
  };

  return (
    <HStack
      spacing="1rem"
      paddingLeft={{ base: "1.35rem", md: "2rem", xl: "4.75rem" }}
      background="grey"
      alignItems="stretch"
      justifyContent="space-between"
      width="100%"
      divider={
        <StackDivider width="0.0675rem" borderColor="rgba(0, 0, 0, 0.1)" />
      }
    >
      <PublishersSwitch
        pickTab={pickTab}
        activeTab={activeTab}
        publishers={publisherList?.publishingHouses ?? []}
      />
      <Suspense
        fallback={
          <Loader
            flexDirection="column"
            minWidth={{
              base: "26.25rem",
              lg: "48.125rem",
              xl: "66.625rem",
              "2xl": "66.875rem",
            }}
            width={{ base: "70%", sm: "73.1%" }}
          />
        }
      >
        <PublisherBookList
          {...publisherList?.publishingHouses.find(
            (publisher) => publisher.id === activeTab
          ) }
        />
      </Suspense>
    </HStack>
  );
};
