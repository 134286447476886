 const objectToUrlParams = (parametersObject: object): string => {
    const parameters = Object.entries(parametersObject)
    .map((param) => {
      if (typeof param[1] === "boolean" && param[1]) {
        return `${param[0]}=${param[1]}`;
      } else if (typeof param[1] === "object" && param[1].length) {
        return param[0] + "=" + param[1].map((item: string) => item).join(",");
      } else if (typeof param[1] === "string" && param[1].length) {
        return `${param[0]}=${param[1]}`;
      } else {
        return "";
      }
    })
    .filter((parameter) => parameter !== "")
    .join("&");

    return parameters;
}

export default objectToUrlParams;