import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Show,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { FunctionComponent } from "react";
import { userDataScheme } from "../../../../utilities/validationSchema";
import RefreshIcon from "../../../../ui/icons/RefreshIcon";
import { useUpdateUserData, useUsersData } from "../../api/client";
import { ToastContainer, toast } from "react-toastify";
import { updateEmail, updateProfile } from "firebase/auth";
import SignInModal from "../SignInModal/SignInModal";
import { showErrorToast, showSuccessToast } from "../../../../utilities/toasts";
import Loader from "../../../../ui/components/Loader/Loader";
import FormInput from "../../../../ui/components/FormInput/FormInput";
import useActualUser from "../../../../utilities/useActualUser";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";
interface PersonalInfoProps {}

const PersonalInfo: FunctionComponent<PersonalInfoProps> = () => {
  const { user } = useActualUser();

  const { data: userData, isLoading, error } = useUsersData(user?.uid ?? "");

  const {
    data: response,
    mutate: updateUser,
    isLoading: userUpdating,
    error: updateError,
  } = useUpdateUserData();

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  return (
    <Box
      flexGrow={1}
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
    >
      <Show below="sm">
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { path: "/profile", title: "Мій кабінет" },
            { title: "Особисті дані" },
          ]}
        />
      </Show>
      <Text
        marginTop={{ base: "1rem !important", sm: "0" }}
        fontWeight={600}
        fontSize={{
          base: "1.25rem",
          sm: "0.75rem",
          md: "1rem",
          xl: "1.5rem",
        }}
      >
        Особисті дані
      </Text>
      {isLoading ? (
        <Loader minHeight="10vh" width="100%" />
      ) : (
        userData && (
          <Formik
            validationSchema={userDataScheme}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              name: userData?.name ?? "",
              surname: userData?.surname ?? "",
              email: userData?.email ?? "",
            }}
            onSubmit={async (values, actions) => {
              try {
                if (user) {
                  if (user.displayName !== `${values.name} ${values.surname}`) {
                    await updateProfile(user, {
                      displayName: `${values.name} ${values.surname}`,
                    });
                  }

                  if (user.email !== values.email) {
                    await updateEmail(user, values.email);
                  }
                }
                updateUser(
                  {
                    userId: user?.uid ?? "",
                    userData: {
                      name: values.name,
                      surname: values.surname,
                      email: values.email,
                    },
                  },
                  {
                    onSuccess: () => {
                      showSuccessToast("Дані успішно оновлені.");
                      setTimeout(() => window.location.reload(), 1500);
                    },
                    onError: () => showErrorToast("Щось пішло не так!"),
                  }
                );
              } catch (error: any) {
                if (error?.code == "auth/requires-recent-login") {
                  onModalOpen();
                }
                showErrorToast("Шось пішло не так! Спробуйте ще раз.");
              }
            }}
          >
            {(props) => (
              <Form>
                <Stack
                  width={{ base: "100%", md: "80%", "2xl": "68.25%" }}
                  marginTop={{
                    base: "2rem",
                    sm: "1.5rem",
                    md: "1.75rem",
                    xl: "2rem",
                    "2xl": "2.5rem",
                  }}
                  spacing={{
                    base: "1.5rem",
                    sm: "1rem",
                    md: "1.25rem",
                    lg: "1.5rem",
                    xl: "2rem",
                  }}
                >
                  <FormInput
                    name="name"
                    label="Ім'я"
                    type="name"
                    placeholder="Введіть ваше ім'я"
                  />
                  <FormInput
                    name="surname"
                    label="Прізвище"
                    type="name"
                    placeholder="Введіть ваше прізвище"
                  />

                  <FormInput
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Введіть email адресу"
                  />

                  <Button
                    isLoading={userUpdating}
                    minWidth={{ base: "100%", sm: "60%", lg: "42.5%" }}
                    type="submit"
                    height="fit-content"
                    transition="all 0.3s"
                    _active={{}}
                    _hover={{ backgroundColor: "black" }}
                    cursor="pointer"
                    borderRadius={{
                      base: "0.5rem",
                      sm: "0.35rem",
                      xl: "0.5rem",
                    }}
                    backgroundColor="orange"
                    width="fit-content"
                    py={{
                      base: "1.125rem",
                      sm: "0.75rem",
                      md: "1rem",
                      xl: "1.125rem",
                    }}
                  >
                    <HStack alignItems="center" spacing="0.75rem">
                      <RefreshIcon
                        width={{
                          base: "1rem",
                          sm: "0.865rem",
                          xl: "1.25rem",
                        }}
                      />
                      <Text
                        line-height="1.25rem"
                        fontWeight={400}
                        color="white"
                        size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                      >
                        Оновити дані
                      </Text>
                    </HStack>
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        )
      )}
      <SignInModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
      />
      <ToastContainer />
    </Box>
  );
};

export default PersonalInfo;
