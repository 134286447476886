import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";
import { Category } from "../Category.tsx/Category";
import { CategoryType } from "../../api/types";
import { Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useCategories } from "../../api/client";
import ErrorMessage from "../../../../ui/components/ErrorMessage/ErrorMessage";
import { useBreakpoint } from "@chakra-ui/media-query";
export const CategoryList: React.FC = () => {
  const {data: categoryList, isLoading, error} = useCategories()
  const breakpoint = useBreakpoint();

  if(error) {
    return(
      <ErrorMessage/>
    )
  }

  return (
    <Box>
      <Flex
        alignItems="center"
        marginBottom={{ base: "1rem", sm: "0.5rem", md: "1rem", xl: "1.5rem" }}
        justify="space-between"
        px={{ base: "0.5rem", sm: "0rem" }}
      >
        <Text
          lineHeight="1"
          fontWeight={400}
          fontSize={{ base: "1.25rem", sm: "1rem", xl: "1.5rem" }}
          variant="primary"
        >
          Категорії
        </Text>
        <NavLink style={{zIndex: 10}} to="/categories">
          <Flex
            alignItems="center"
            gap={{ base: "0.5rem", md: "0.75rem", xl: "1rem" }}
          >
            <Text
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              variant="link"
            >
              Дивитись всі
            </Text>
            <Box boxSize={{ base: "1rem", sm: "0.5rem", md: "1rem" }}>
              <BiChevronRight size="100%" color="#FF5500" />
            </Box>
          </Flex>
        </NavLink>
      </Flex>
      <Carousel
        childrenType="categories"
        hasButtons={breakpoint !== 'base'}
        gap={{
          base: "2.9%",
          sm: "1.7%",
          md: "1.7%",
          xl: "1.5%",
          "2xl": "1.108%",
        }}
      >
        {categoryList?.categories?.map((category: CategoryType, index: number) => {
          return <Category key={index} {...category} />;
        })}
      </Carousel>
    </Box>
  );
};
