import { useLocation, useParams } from "react-router-dom";
import { Box, Show, VStack, useBreakpoint } from "@chakra-ui/react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import BookDetailsSection from "../../features/BookDetailsPage/components/BookDetailsSection/BookDetailsSection";
import BookList from "../../features/BookDetailsPage/components/BookList/BookList";
import MobileBookDetailsSection from "../../features/BookDetailsPage/components/BookDetailsSection/MobileBooksDetailSection";
import { useBookDetails } from "../../features/BookDetailsPage/api/client";
import ErrorMessage from "../../ui/components/ErrorMessage/ErrorMessage";
import MyContentLoader from "../../features/BookDetailsPage/components/ContentLoader/ContentLoader";
import MobileContentLoader from "../../features/BookDetailsPage/components/ContentLoader/MobileContentLoader";
import { useEffect } from "react";

const BookDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { data: book, isLoading, error } = useBookDetails(id ?? "");
  const breakpoint = useBreakpoint();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location]);

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Box
      paddingLeft={{
        base: "0.5rem",
        md: "1rem",
        xl: "2rem",
        "2xl": "4.75rem",
      }}
      paddingRight={{
        base: "0.5rem",
        md: "0.875rem",
        xl: "1.875rem",
        "2xl": "4.25rem",
      }}
    >
      {isLoading ? (
        <Box width="100%">
          <Show ssr={false} above="sm">
            <MyContentLoader />
          </Show>
          <Show ssr={false} below="sm">
            <MobileContentLoader />
          </Show>
        </Box>
      ) : (
        <Box maxWidth="100%">
          <Breadcrumb
            breadcrumbItems={[
              { path: "/", title: "Головна" },
              { path: "/categories", title: "Категорії" },
              {
                path: `/categories/${book?.categories[0] ?? "/"}`,
                title: book?.categories[0] ?? "",
                payload: {
                  categoryName: book?.categories[0],
                },
              },
              { title: book?.name ?? "" },
            ]}
          />
          <VStack
            spacing={{ base: "3rem", sm: "2rem", md: "3.5rem", xl: "5rem" }}
            marginTop={{ base: "1.25rem", md: "1.5rem", xl: "2rem" }}
          >
            {book && (
              <Box width="100%">
                <Show ssr={false} below="sm">
                  <MobileBookDetailsSection {...book} id={id ?? ""} />
                </Show>
                <Show ssr={false} above="sm">
                  <BookDetailsSection {...book} id={id ?? ""} />
                </Show>
              </Box>
            )}
            <BookList type="authors" name={book?.authors[0] ?? ""} />
            <BookList type="publishers" name={book?.publishingHouse ?? ""} />
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default BookDetailsPage;
