import { Show } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import SidePanel from "../SidePanel/SidePanel";
import { Navigate } from "react-router-dom";
interface LayoutProps {}

const ProfileLayout: FunctionComponent<LayoutProps> = () => {
  return (
    <>
      <Show above="sm">
        <Navigate to="my-books" />
      </Show>
      <Show below="sm">
        <SidePanel />
      </Show>
    </>
  );
};

export default ProfileLayout;
