import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";
import { Book } from "../Book/Book";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { BookPreviewType } from "../../api/types";
import { useBreakpoint } from "@chakra-ui/react";
import { useBooks } from "../../api/client";
import ErrorMessage from "../../../../ui/components/ErrorMessage/ErrorMessage";
interface BookListProps {
  title: string;
  type: 'bestsellerBooks' | 'newcomingBooks';
}

export const BookList: React.FC<BookListProps> = ({ title, type='bestsellerBooks' }) => {
  const breakpoint = useBreakpoint();
  const { data: bookList, isLoading, error } = useBooks(type, 1, 20, {suspense: true});

  if(error) {
    return(
      <ErrorMessage/>
    )
  }

  return (
    <Box>
      <Flex
        
        alignItems="center"
        marginBottom={{
          base: "1.25rem",
          sm: "0.5rem",
          md: "1rem",
          xl: "1.5rem",
        }}
        justify="space-between"
        px={{ base: "0.5rem", sm: "0rem" }}
      >
        <Text
          lineHeight="1"
          fontWeight={400}
          fontSize={{ base: "1.25rem", sm: "1rem", xl: "1.5rem" }}
          variant="primary"
        >
          {title}
        </Text>
        <NavLink style={{zIndex:10}} to={`/books/${type === "bestsellerBooks"? 'bestsellers' : 'newcomings'}`}>
          <Flex
            alignItems="center"
            gap={{ base: "0.5rem", md: "0.75rem", xl: "1rem" }}
          >
            <Text
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              variant="link"
            >
              Дивитись всі
            </Text>
            <Box boxSize={{ base: "1rem", sm: "0.5rem", md: "1rem" }}>
              <BiChevronRight size="100%" color="#FF5500" />
            </Box>
          </Flex>
        </NavLink>
      </Flex>
      <Carousel
        hasButtons={breakpoint !== "base"}
        gap={{ base: "6%", sm: "1.7%", md: "2.5%", xl: "1.8%", "2xl": "1.79%" }}
      >
        {bookList?.books.map((book: BookPreviewType, index: number) => {
          return (
            <Book
              minWidth={{ base: "59.5%", sm: "20%", md: "18%", xl: "15.15%" }}
              maxWidth={{ base: "59.5%", sm: "20%", md: "18%", xl: "15.15%" }}
              leaveBottomSpace={true}
              key={index}
              id={book.id}
              authors={book.authors}
              name={book.name}
              cover={book.cover}
            />
          );
        })}
      </Carousel>
    </Box>
  );
};
