import { Box, Divider, HStack, Image, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";
import BookIcon from "../../../../ui/icons/BookIcon";
import UserIcon from "../../../../ui/icons/UserIcon";
import LockIcon from "../../../../ui/icons/LockIcon";
import LogoutIcon from "../../../../ui/icons/LogoutIcon";
import useActualUser from "../../../../utilities/useActualUser";
import { signOut } from "firebase/auth";
import { auth } from "../../../../utilities/firebase";
import paintBrush from "../../../../assets/Orange Paint.svg";
import { NavLink } from "react-router-dom";

const SidePanel: FunctionComponent = () => {
  const { user } = useActualUser();
  return (
    <Stack
      justifyContent="space-between"
      gap="3rem"
      flexGrow={{ base: 1, sm: 0 }}
      minHeight="70vh"
      height="fit-content"
      overflow="clip"
      position="relative"
      flexShrink={0}
      minWidth={{ base: "12rem", sm:'15rem', md: "17rem", "2xl": "21rem" }}
      width={{ base: "27%", "2xl": "23.5%" }}
      borderRadius={{
        base: "1.25rem",
        sm: "1rem",
        md: "1.5rem",
        xl: "2rem",
      }}
      backgroundColor="grey"
      py={{
        base: "1.5rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
    >
      <Stack
        gap={{
          base: "1.75rem",
          sm: "1.5rem",
          md: "1.75rem",
          xl: "2rem",
          "2xl": "2.5rem",
        }}
      >
        <Box
          px={{
            base: "1.5rem",
            md: "1.75rem",
            xl: "2rem",
            "2xl": "2.5rem",
          }}
        >
          <Breadcrumb
            breadcrumbItems={[
              { title: "Головна", path: "/" },
              { title: "Мій кабінет" },
            ]}
          />
          <Text
            marginTop={{ base: "1.5rem", sm: "1rem", xl:'2.25rem' }}
            marginBottom={{ base: "0.75rem", sm: "auto" }}
            whiteSpace="normal"
            overflowWrap="normal"
            zIndex={10}
            maxWidth={{ base: "70%", sm: "60%" }}
            fontSize={{
              base: "1.75rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
          >
            {user?.displayName ?? "Мій кабінет"}
          </Text>
        </Box>
        <Divider
          margin="0 !important"
          width="100%"
          backgroundColor="black"
          height="0.125rem"
        />
        <Stack
          px={{
            base: "1.75rem",
            xl: "2rem",
            "2xl": "2.5rem",
          }}
          justifyContent="space-between"
        >
          <Stack gap="2.5rem">
            <NavLink to="my-books">
              {({ isActive, isPending }) => (
                <HStack
                  alignItems="center"
                  spacing={{ base: "0.875rem", sm: "0.5rem", xl: "0.875rem" }}
                  cursor="pointer"
                >
                  <BookIcon
                    fill={isActive ? "#FF5500" : "black"}
                    height="fit-content"
                    width={{
                      base: "1.35rem",
                      sm: "0.865rem",
                      md: "1rem",
                      xl: "1.35rem",
                    }}
                  />
                  <Text
                    color={isActive ? "orange" : "black"}
                    size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                  >
                    Придбані книги
                  </Text>
                </HStack>
              )}
            </NavLink>
            <NavLink to="personal-info">
              {({ isActive, isPending }) => (
                <HStack
                  alignItems="center"
                  spacing={{ base: "0.875rem", sm: "0.5rem", xl: "0.875rem" }}
                  cursor="pointer"
                >
                  <UserIcon
                    fill={isActive ? "#FF5500" : "black"}
                    height="fit-content"
                    width={{
                      base: "1.35rem",
                      sm: "0.865rem",
                      md: "1rem",
                      xl: "1.35rem",
                    }}
                  />
                  <Text
                    color={isActive ? "orange" : "black"}
                    size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                  >
                    Особисті дані
                  </Text>
                </HStack>
              )}
            </NavLink>
            <NavLink to="change-password">
              {({ isActive, isPending }) => (
                <HStack
                  alignItems="center"
                  spacing={{ base: "0.875rem", sm: "0.35rem", xl: "0.75rem" }}
                  cursor="pointer"
                >
                  <LockIcon
                    fill={isActive ? "#FF5500" : "black"}
                    height="fit-content"
                    width={{
                      base: "1.35rem",
                      sm: "0.865rem",
                      md: "1rem",
                      xl: "1.35rem",
                    }}
                  />
                  <Text
                    color={isActive ? "orange" : "black"}
                    size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                  >
                    Змінити пароль
                  </Text>
                </HStack>
              )}
            </NavLink>
          </Stack>
        </Stack>
      </Stack>
      <HStack
        px={{
          base: "1.75rem",
          sm: "1.5rem",
          md: "1.75rem",
          xl: "2rem",
          "2xl": "2.5rem",
        }}
        onClick={() => signOut(auth)}
        cursor="pointer"
        alignItems="center"
        spacing={{ base: "0.875rem", sm: "0.5rem", xl: "0.875rem" }}
      >
        <LogoutIcon
          width={{
            base: "1rem",
            sm: "0.865rem",
            md: "0.865rem",
            xl: "1.25rem",
          }}
        />
        <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>Вийти</Text>
      </HStack>
      <Image
        minWidth={{
          base: "5rem",
          md: "6.5rem",
          xl: "8rem",
          "2xl": "auto",
        }}
        margin="0 !important"
        src={paintBrush}
        position="absolute"
        width={{ base: "34%", sm: "25%", md: "27%", "2xl": "41.5%" }}
        top="0"
        right="0"
      />
    </Stack>
  );
};

export default SidePanel;
