import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { BookType } from "../../api/types";
import { NavLink, useNavigate } from "react-router-dom";
import linePattern from "../../../../assets/Line Pattern.svg";
import { usePurchaseBook, useUserHasBook } from "../../api/client";
import { auth } from "../../../../utilities/firebase";
import { useEffect } from "react";
import defaultCover from "../../../../assets/default-book-cover.jpg";
import MyContentLoader from "../ContentLoader/ContentLoader";
import { showSuccessToast } from "../../../../utilities/toasts";
const BookDetailsSection: React.FC<BookType> = ({
  authors,
  cover,
  description,
  name,
  price,
  publishingHouse,
  numberOfPages,
  originalName,
  releaseYear,
  id,
}) => {
  const {
    data: userHasBook,
    isLoading,
    error,
  } = useUserHasBook(auth.currentUser?.uid ?? "", id);

  const navigate = useNavigate();

  const {
    mutate: purchase,
    data: invoice,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = usePurchaseBook(auth.currentUser?.uid ?? "", id);

  useEffect(() => {
    if (invoice?.pageUrl) {
      window.location.assign(invoice?.pageUrl);
    } else if (invoiceError) {
      showErrorToast("Щось пішло не так!");
    } else {
      if (invoice?.status == "success") {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        showSuccessToast("Книжка була успішно додана");
      }
    }
  }, [invoice?.pageUrl, invoiceError, invoice?.status]);

  const showErrorToast = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
    });
  };

  const purchaseBook = () => {
    if (auth.currentUser === null) {
      navigate("/profile", { relative: "path" });
      return;
    }
    purchase({
      userId: auth.currentUser.uid,
      bookId: id,
    });
  };

  return (
    <Stack
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      gap="3.15%"
    >
      <Image
        width={{ base: "20rem", md: "23rem", xl: "30rem", "2xl": "31.75rem" }}
        minWidth={{
          base: "20rem",
          md: "23rem",
          xl: "30rem",
          "2xl": "31.75rem",
        }}
        fallbackStrategy="onError"
        fallbackSrc={defaultCover}
        borderRadius="1.25rem"
        src={cover === "" ? "https://localhost:65536/" : cover}
        alt="Book cover"
      />
      <Box flexGrow={1}>
        <Stack
          marginBottom={{ base: "1rem", md: "1.5rem", xl: "3rem" }}
          alignItems="flex-start"
          spacing={{
            base: "1rem",
            md: "0.75rem",
            xl: "1.25rem",
            "2xl": "1.5rem",
          }}
          divider={
            <StackDivider
              bgRepeat="no-repeat"
              border="none"
              height={{ base: "0.25rem", xl: "0.25rem" }}
              bgImage={linePattern}
            />
          }
        >
          <Stack
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
            paddingTop={{
              base: "0.75rem",
              sm: "1rem",
              xl: "1.5rem",
              "2xl": "2rem",
            }}
          >
            <Box>
              <Text
                fontFamily="GaretHeavy"
                as="h1"
                lineHeight={{ base: "1.5rem", md: "2rem", xl: "3.25rem" }}
                marginBottom="0.25rem"
                fontSize={{ base: "1.25rem", md: "1.75rem", xl: "2rem" }}
                fontWeight={850}
              >
                {name}
              </Text>
              <NavLink to={`/authors/${authors[0]}`}>
                <Text
                  fontWeight={500}
                  variant="link"
                  fontSize={{ base: "0.75rem", md: "1rem", xl: "1.25rem" }}
                >
                  {authors[0]}
                </Text>
              </NavLink>
            </Box>
            <Stack
              gap="0.25rem"
              flexDirection="row"
              alignItems="baseline"
              fontWeight={400}
            >
              <Text
                as="h3"
                fontSize={{
                  base: "1rem",
                  sm: "1.5rem",
                  md: "1,75rem",
                  xl: "2.25rem",
                }}
              >
                {!isNaN(price) ? price ?? 0 : 0}
              </Text>
              <Text fontSize={{ base: "0.75rem", md: "1rem", xl: "1.25rem" }}>
                грн
              </Text>
            </Stack>
          </Stack>
          <HStack spacing={{ base: "2rem", xl: "4rem" }}>
            <Stack
              lineHeight={{ base: "1rem", xl: "1.25rem" }}
              alignItems="flex-start"
              spacing={{ base: "0.75rem", md: "1rem", xl: "1.25rem" }}
            >
              <Text
                lineHeight="inherit"
                variant="secondary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Видавництво
              </Text>
              <Text
                lineHeight="inherit"
                variant="secondary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Рік видання
              </Text>
              <Text
                lineHeight="inherit"
                variant="secondary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Оригінальна назва
              </Text>
              <Text
                lineHeight="inherit"
                variant="secondary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Тип книги
              </Text>
              <Text
                lineHeight="inherit"
                variant="secondary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Кількість сторінок
              </Text>
            </Stack>
            <Stack
              lineHeight={{ base: "1rem", xl: "1.25rem" }}
              alignItems="flex-start"
              spacing={{ base: "0.75rem", md: "1rem", xl: "1.25rem" }}
            >
              <NavLink to={`/publishers/${publishingHouse}`}>
                <Text
                  lineHeight="inherit"
                  fontWeight={500}
                  variant="link"
                  size={{ base: "xs", md: "sm", xl: "md" }}
                >
                  {publishingHouse}
                </Text>
              </NavLink>
              <Text
                lineHeight="inherit"
                fontWeight={500}
                variant="primary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                {releaseYear ?? 2023}
              </Text>
              <Text
                lineHeight="inherit"
                fontWeight={500}
                variant="primary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                {originalName ?? "Оригінальна назва"}
              </Text>
              <Text
                lineHeight="inherit"
                fontWeight={500}
                variant="primary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                Електронна книга
              </Text>
              <Text
                lineHeight="inherit"
                fontWeight={500}
                variant="primary"
                size={{ base: "xs", md: "sm", xl: "md" }}
              >
                {numberOfPages ?? 0}
              </Text>
            </Stack>
          </HStack>
          <Box>
            <Text
              marginBottom={{ base: "0.25rem", md: "0.5rem", xl: "0.75rem" }}
              lineHeight="1.8rem"
              fontWeight={400}
              size={{ base: "sm", md: "md", xl: "lg" }}
            >
              Опис
            </Text>
            <Text
              lineHeight={{ base: "1rem", xl: "1.5rem" }}
              size={{ base: "xs", md: "sm", xl: "md" }}
            >
              {description}
            </Text>
          </Box>
        </Stack>
        {!userHasBook?.userHasBook ? (
          <Button
            _hover={{ backgroundColor: "black" }}
            isLoading={isInvoiceLoading || isLoading}
            disabled={!userHasBook?.userHasBook}
            onClick={purchaseBook}
            transition="all 0.3s"
            height="auto"
            backgroundColor="orange"
            py={{ base: "0.75rem", md: "1rem", xl: "1.125rem" }}
            px={{ base: "1rem", md: "1.25rem", xl: "1.375rem" }}
          >
            <Text
              lineHeight="1.25rem"
              size={{ base: "xs", md: "sm", xl: "md" }}
              color="white"
            >
              {price == 0
                ? "Додати книгу безкоштовно"
                : "Придбати і читати в застосунку"}
            </Text>
          </Button>
        ) : (
          <Box
            borderRadius="0.375rem"
            width="fit-content"
            backgroundColor="orange"
            py={{ base: "0.75rem", md: "1rem", xl: "1.125rem" }}
            px={{ base: "1rem", md: "1.25rem", xl: "1.375rem" }}
          >
            <Text
              fontWeight={600}
              lineHeight="1.25rem"
              size={{ base: "xs", md: "sm", xl: "md" }}
              color="white"
            >
              Ви вже придбали цю книгу
            </Text>
          </Box>
        )}
      </Box>
      <ToastContainer />
    </Stack>
  );
};

export default BookDetailsSection;
