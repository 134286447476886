import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { Formik, Form } from "formik";
import { signInScheme } from "../../../../utilities/validationSchema";
import { auth } from "../../../../utilities/firebase";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { showErrorToast, showSuccessToast } from "../../../../utilities/toasts";
import FormInput from "../../../../ui/components/FormInput/FormInput";
import { ToastContainer } from "react-toastify";

interface SignInModalProps {
  isModalOpen: boolean;
  onModalOpen(): void;
  onModalClose(): void;
}

const SignInModal: FunctionComponent<SignInModalProps> = ({
  isModalOpen,
  onModalClose,
  onModalOpen,
}) => {

  return (
    <Modal isCentered isOpen={isModalOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent maxWidth='90%'>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text
            textAlign="center"
            size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            fontWeight={600}
            color="orange"
          >
            Для даної дії необхідно ще раз увійти в аккаунт.
          </Text>
          <Formik
            validationSchema={signInScheme}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: "", password: "" }}
            onSubmit={async (values, actions) => {
              try {
                if (auth.currentUser?.email !== values.email) {
                  throw Error;
                }
                const credential = EmailAuthProvider.credential(
                  auth.currentUser.email,
                  values.password
                );
                await reauthenticateWithCredential(
                  auth.currentUser,
                  credential
                );
                onModalClose();
                showSuccessToast("Ви успішно увійшли в аккаунт.");
              } catch (error) {
                showErrorToast(
                  "Неправильний email або пароль, спробуйте ще раз!"
                );
              }
            }}
          >
            {(props) => (
              <Form>
                <Stack
                  marginTop={{
                    base: "1.25rem",
                    sm: "1rem",
                    md: "1.25rem",
                    lg: "1.5rem",
                    xl: "2rem",
                  }}
                  spacing={{
                    base: "1.25rem",
                    sm: "1rem",
                    md: "1.25rem",
                    lg: "1.5rem",
                    xl: "2rem",
                  }}
                >
                  <FormInput
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Введіть email адресу"
                  />
                  <FormInput
                    name="password"
                    label="Пароль"
                    type="password"
                    placeholder="Введіть пароль"
                    isResetable={true}
                  />

                  <HStack
                    paddingTop="1rem"
                    width="100%"
                    justifyContent="space-around"
                  >
                    <Button
                      minWidth="fit-content"
                      type="submit"
                      flexBasis="40%"
                      height="fit-content"
                      transition="all 0.3s"
                      _active={{}}
                      _hover={{ backgroundColor: "black" }}
                      cursor="pointer"
                      borderRadius={{
                        base: "0.5rem",
                        sm: "0.35rem",
                        xl: "0.5rem",
                      }}
                      backgroundColor="orange"
                      width="fit-content"
                      py={{
                        base: "1.125rem",
                        sm: "0.75rem",
                        md: "1rem",
                        xl: "1.125rem",
                      }}
                    >
                      <HStack alignItems="center" spacing="0.75rem">
                        <Text
                          line-height="1.25rem"
                          fontWeight={400}
                          color="white"
                          size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                        >
                          Увійти
                        </Text>
                      </HStack>
                    </Button>
                    <Button
                      onClick={onModalClose}
                      height="fit-content"
                      transition="all 0.3s"
                      _active={{}}
                      _hover={{ backgroundColor: "black" }}
                      cursor="pointer"
                      borderRadius={{
                        base: "0.5rem",
                        sm: "0.35rem",
                        xl: "0.5rem",
                      }}
                      backgroundColor="orange"
                      minWidth="fit-content"
                      flexBasis="40%"
                      py={{
                        base: "1.125rem",
                        sm: "0.75rem",
                        md: "1rem",
                        xl: "1.125rem",
                      }}
                    >
                      <Text
                        line-height="1.25rem"
                        fontWeight={400}
                        color="white"
                        size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                      >
                        Скасувати
                      </Text>
                    </Button>
                  </HStack>
                </Stack>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
