import { Flex, Show } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { FilterOptionsType } from "../../features/CataloguePage/api/types";
import FilterPanel from "../../features/CataloguePage/components/FilterPanel/FilterPanel";
import BooksCatalogue from "../../features/CataloguePage/components/BooksCatalogue/BooksCatalogue";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import objectToUrlParams from "../../utilities/objectToUrlParams";
import MobileFilterPanel from "../../features/CataloguePage/components/FilterPanel/MobileFilterPanel";
import MobileBooksCatalogue from "../../features/CataloguePage/components/BooksCatalogue/MobileBooksCatalogue";
const CataloguePage: FunctionComponent = () => {
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>({
    bestsellers: false,
    newcomings: false,
    categories: [],
    authors: [],
    publishers: [],
    genres: [],
    searchParameter: "",
  });

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setFilterOptions({
      bestsellers: searchParams.get("bestsellers") === "true",
      newcomings: searchParams.get("newcomings") === "true",
      authors: searchParams.get("authors")?.split(",") ?? [],
      publishers: searchParams.get("publishers")?.split(",") ?? [],
      genres: searchParams.get("genres")?.split(",") ?? [],
      categories: searchParams.get("categories")?.split(",") ?? [],
      searchParameter: searchParams.get("searchParameter") ?? undefined,
    });
  }, []);
    
  useEffect(() => {
    const path = objectToUrlParams(filterOptions);
    navigate(`/catalogue?${path}`);
  }, [filterOptions]);

  return (
    <Flex
      alignItems="stretch"
      paddingRight={{
        base: "1rem",
        md: "2rem",
        xl: "3.5rem",
        "2xl": "4.75rem",
      }}
      gap="4.41%"
    >
      <Show above="sm">
        <FilterPanel
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
        <BooksCatalogue
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </Show>

      <Show below="sm">
        <MobileFilterPanel
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          showFilterPanel={showFilterPanel}
          setShowFilterPanel={setShowFilterPanel}
        />
        <MobileBooksCatalogue
          setShowFilterPanel={setShowFilterPanel}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </Show>
    </Flex>
  );
};

export default CataloguePage;
