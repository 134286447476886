import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";

interface LogoutIconProps extends StyleProps {
  fill?: string;
}

const LogoutIcon: React.FC<LogoutIconProps> = (props) => {
  return (
    <Icon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.293031 8.293L4.29303 4.293C4.38528 4.19749 4.49562 4.12131 4.61763 4.0689C4.73963 4.01649 4.87085 3.9889 5.00363 3.98775C5.13641 3.9866 5.26809 4.0119 5.39098 4.06218C5.51388 4.11246 5.62553 4.18671 5.71943 4.2806C5.81332 4.3745 5.88757 4.48615 5.93785 4.60905C5.98813 4.73194 6.01344 4.86362 6.01228 4.9964C6.01113 5.12918 5.98354 5.2604 5.93113 5.3824C5.87872 5.50441 5.80254 5.61475 5.70703 5.707L3.41403 8H15C15.2652 8 15.5196 8.10536 15.7071 8.29289C15.8947 8.48043 16 8.73478 16 9C16 9.26522 15.8947 9.51957 15.7071 9.70711C15.5196 9.89464 15.2652 10 15 10H3.41403L5.70703 12.293C5.80254 12.3852 5.87872 12.4956 5.93113 12.6176C5.98354 12.7396 6.01113 12.8708 6.01228 13.0036C6.01344 13.1364 5.98813 13.2681 5.93785 13.391C5.88757 13.5138 5.81332 13.6255 5.71943 13.7194C5.62553 13.8133 5.51388 13.8875 5.39098 13.9378C5.26809 13.9881 5.13641 14.0134 5.00363 14.0123C4.87085 14.0111 4.73963 13.9835 4.61763 13.9311C4.49562 13.8787 4.38528 13.8025 4.29303 13.707L0.293031 9.707C0.105559 9.51947 0.000244141 9.26516 0.000244141 9C0.000244141 8.73484 0.105559 8.48053 0.293031 8.293ZM18 1V17C18 17.2652 18.1054 17.5196 18.2929 17.7071C18.4805 17.8946 18.7348 18 19 18C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8947 17.5196 20 17.2652 20 17V1C20 0.734784 19.8947 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0C18.7348 0 18.4805 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1Z"
        fill="black"
      />
    </Icon>
  );
};

export default LogoutIcon;
