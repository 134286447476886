import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import notFoundImage from "../../assets/NotFoundImage.svg";
import { Header } from "../../ui/layouts/DefaultLayout/Header";
import { ArrowIcon } from "../../ui/icons/ArrowIcon";
import { NavLink } from "react-router-dom";
import { auth } from "../../utilities/firebase";
interface NotFoundPageProps {}

const NotFoundPage: FunctionComponent<NotFoundPageProps> = () => {
  // const isLoggedIn = auth.currentUser !== null;
  return (
    <Stack height="100vh">
      <Header />
      <Stack
        gap="3rem"
        margin="0 !important"
        flexGrow={1}
        backgroundColor="#F0F0F0"
        position={{ base: "static", md: "relative" }}
        px={{
          base: "1.25rem",
          sm: "1.5rem",
          md: "3rem",
          xl: "4rem",
          "2xl": "8.5rem",
        }}
        paddingTop="2.5rem"
        paddingBottom="11.2rem"
      >
        <Box
          order={2}
          zIndex={100}
          paddingLeft={{base:"0", md:"inherit"}}
          bottom={{ base: "10%", xl: "12%", "2xl": "18%" }}
          position={{ base: "static", md: "absolute" }}
          left="0rem"
        >
          <Text size={{ base: "lg", sm: "md", xl: "lg" }}>
            Сторінку не знайдено!
          </Text>
          <Text
            marginTop={{
              base: "0.75rem",
              sm: "1rem",
              xl: "1.5rem",
              "2xl": "2rem",
            }}
            marginBottom="1rem"
            width={{ base: "100%", md: "60.6%" }}
            fontWeight={850}
            fontFamily="GaretHeavy"
            fontSize={{
              base: "1.25rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
          >
            “Як ліки не завжди приємні, так і істина буває сувора.”
          </Text>
          <Text size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}>
            Григорій Сковорода
          </Text>
        </Box>
        <Image
          order={1}
          margin="0rem !important"
          paddingTop={{ base: "0rem", md: "inherit" }}
          position={{ base: "static", md: "absolute" }}
          top="0"
          left="50%"
          transform={{ base: "", md: "translateX(-50%)" }}
          width={{ base: "100%", md: "auto" }}
          height={{ base: "auto", md: "95.5%" }}
          src={notFoundImage}
        />
        <Box order={3} px={{ base:'0', md:'inherit'}}>
          <NavLink
            style={{
              paddingLeft: "inherit",
              paddingRight: "inherit",
              order: 3,
            }}
            to="/"
          >
            <Flex
              gap="0.875rem"
              alignItems="center"
              bottom="50%"
              transform="translateY(-50%)"
              right="0"
              position={{ base: "static", md: "absolute" }}
              px="inherit"
            >
              <Text
                size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                fontWeight={500}
                variant="link"
              >
                На головну
              </Text>
              <ArrowIcon
                width="18px"
                transform="rotate(90deg)"
                fill="#FF5500"
              />
            </Flex>
          </NavLink>
        </Box>
      </Stack>
    </Stack>
  );
};

export default NotFoundPage;
