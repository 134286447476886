import { NavLink } from "react-router-dom";
import { PublisherBookItem } from "../PublisherBookItem/PublisherBookItem";
import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import { PublisherBookType, PublisherType } from "../../api/types";
import { useBookList } from "../../../BookDetailsPage/api/client";

interface PublisherBookListProps {
  id?: string;
  name?: string;
  logoUrl?: string;
}

export const PublisherBookList: React.FC<PublisherBookListProps> = ({
  id,
  logoUrl,
  name,
}) => {
  const booksDisplayed = useBreakpointValue({ base: 4, md: 6 }, { ssr: false });

  const {
    data: bookList,
    isLoading,
    error,
  } = useBookList("publishers")(name ?? "", 1, booksDisplayed ?? 6, {
    suspense: true,
  });

  if (error) {
    return (
      <Flex
        alignItems="center"
        flexGrow={1}
        marginRight={{ base: "0.75rem", sm: "0rem" }}
        py={{ base: "1.5rem", xl: "2rem" }}
        borderRadius="1rem"
        backgroundColor="#FF726F"
        justifyContent="center"
      >
        <Text
          fontWeight={500}
          color={"white"}
          size={{ base: "lg", sm: "md", xl: "lg" }}
        >
          Щось пішло не так!
        </Text>
      </Flex>
    );
  }

  return (
    <Box
      minWidth={{
        base: "26.25rem",
        lg: "48.125rem",
        xl: "66.625rem",
        "2xl": "66.875rem",
      }}
      width={{ base: "70%", sm: "73.1%" }}
      paddingTop="1.25rem"
    >
      <Flex
        alignItems="center"
        marginBottom={{ base: "0.5rem", md: "1rem", xl: "1.5rem" }}
        justify="space-between"
      >
        <Text
          as="h1"
          lineHeight="1"
          fontWeight={400}
          size={{ base: "sm", md: "md", xl: "lg" }}
          variant="primary"
        >
          Книжки видавництва
        </Text>
        <NavLink to={`/publishers/${name ?? ""}`} state={{ logoUrl: logoUrl }}>
          <Flex
            alignItems="center"
            gap={{ base: "0.25rem", md: "0.35rem", xl: "0.5rem" }}
          >
            <Text size={{ base: "xs", md: "sm", xl: "md" }} variant="link">
              До видавництва
            </Text>
            <Box boxSize={{ base: "0.5rem", md: "1rem" }}>
              <BiChevronRight size="100%" color="#FF5500" />
            </Box>
          </Flex>
        </NavLink>
      </Flex>
      {bookList?.books.length === 0 ? (
        <Center>
          <Text
            opacity={0.5}
            size={{ base: "xs", md: "md", xl: "lg" }}
            fontWeight={500}
          >
            На даний момент книжок немає
          </Text>
        </Center>
      ) : (
        <SimpleGrid
          alignItems='stretch'
          spacing="1.92%"
          rowGap={{ base: "0.5rem", md: "0.75rem", xl: "1.5rem" }}
          columns={{ base: 2, lg: 3 }}
        >
          {bookList?.books.slice(0, 6).map((book: PublisherBookType) => {
            return <PublisherBookItem key={book.id} {...book} />;
          })}
        </SimpleGrid>
      )}
    </Box>
  );
};
