import { defineStyleConfig } from "@chakra-ui/react";

export const TextStyles = defineStyleConfig({
  baseStyle: {
    fontFamily: "Commissioner, sans-serif",
    color: "#FFF",
  },

  sizes: {
    xs: {
      fontSize: "0.75rem",
    },
    sm: {
      fontSize: "0.75rem",
    },
    caption: {
      fontSize: "0.875rem",
    },
    md: {
      fontSize: "1rem",
    },
    lg: {
      fontSize: "1.5rem",
    },
  },

  variants: {
    primary: {
      color: "black",
    },
    secondary: {
      color: "black",
      opacity: 0.5,
    },
    link: {
      color: "#FF5500",
      fontWeight: 500,
    },
  },

  defaultProps: {
    size: "sm",
    variant: "primary",
  },
});
