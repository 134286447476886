import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BookType } from "../../api/types";
import { NavLink, useNavigate } from "react-router-dom";
import linePattern from "../../../../assets/Line Pattern.svg";
import { usePurchaseBook, useUserHasBook } from "../../api/client";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { auth } from "../../../../utilities/firebase";
import defaultCover from "../../../../assets/default-book-cover.jpg";
import { showErrorToast, showSuccessToast } from "../../../../utilities/toasts";

const MobileBookDetailsSection: React.FC<BookType> = ({
  authors,
  cover,
  description,
  name,
  price,
  publishingHouse,
  numberOfPages,
  originalName,
  releaseYear,
  id,
}) => {
  const {
    data: userHasBook,
    isLoading,
    error,
  } = useUserHasBook(auth.currentUser?.uid ?? "", id);

  const navigate = useNavigate();

  const {
    mutate: purchase,
    data: invoice,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = usePurchaseBook(auth.currentUser?.uid ?? "", id);

  useEffect(() => {
    if (invoice?.pageUrl) {
      window.location.assign(invoice?.pageUrl);
    } else if (invoiceError) {
      showErrorToast("Щось пішло не так!");
    } else {
      if (invoice?.status == "success") {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        showSuccessToast("Книжка була успішно додана");
      }
    }
  }, [invoice?.pageUrl, invoiceError, invoice?.status]);

  const purchaseBook = () => {
    if (auth.currentUser === null) {
      navigate("/profile", { relative: "path" });
      return;
    }
    purchase({
      userId: auth.currentUser.uid,
      bookId: id,
    });
  };
  return (
    <VStack width="100%" alignItems="flex-start" gap="3.15%">
      <Image
        width="100%"
        borderRadius="1.25rem"
        marginBottom="1.25rem"
        fallbackStrategy="onError"
        fallbackSrc={defaultCover}
        src={cover === "" ? "https://localhost:65536/" : cover}
        alt="Book cover"
      />
      <Stack
        marginTop="1.25rem"
        spacing="1.5rem"
        maxWidth="100%"
        divider={
          <StackDivider
            bgRepeat="no-repeat"
            border="none"
            height="0.25rem"
            bgImage={linePattern}
          />
        }
      >
        <Box maxWidth="100%">
          <Stack
            marginBottom="1.5rem"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
            maxWidth="100%"
          >
            <Box>
              <Text
                fontFamily="GaretHeavy"
                as="h1"
                lineHeight="1.5rem"
                marginBottom="0.25rem"
                fontSize="1.25rem"
                fontWeight={850}
              >
                {name}
              </Text>
              <NavLink to={`/authors/${authors[0]}`}>
                <Text fontWeight={500} variant="link" fontSize="1rem">
                  {authors[0]}
                </Text>
              </NavLink>
            </Box>
            <Stack
              maxWidth="100%"
              gap="0.25rem"
              flexDirection="row"
              alignItems="baseline"
              fontWeight={400}
            >
              <Text as="h3" fontSize="1.25rem">
                {!isNaN(price) ? price ?? 0 : 0}
              </Text>
              <Text fontSize="1rem">грн</Text>
            </Stack>
          </Stack>
          {!userHasBook?.userHasBook ? (
            <Button
              _hover={{}}
              _active={{ backgroundColor: "black" }}
              disabled={!userHasBook?.userHasBook}
              isLoading={isInvoiceLoading || isLoading}
              onClick={purchaseBook}
              width="100%"
              transition="all 0.3s"
              height="auto"
              backgroundColor="orange"
              py="1.125rem"
              px="2.625rem"
            >
              <Text
                lineHeight="1.25rem"
                size="md"
                color="white"
                fontWeight={500}
              >
                {price == 0
                  ? "Додати книгу безкоштовно"
                  : "Придбати і читати в застосунку"}
              </Text>
            </Button>
          ) : (
            <Box
              textAlign="center"
              borderRadius="0.375rem"
              width="100%"
              backgroundColor="orange"
              py="1.125rem"
              px="2.625rem"
            >
              <Text
                lineHeight="1.25rem"
                size="md"
                color="white"
                fontWeight={500}
              >
                Ви вже придбали цю книгу
              </Text>
            </Box>
          )}
        </Box>
        <Stack maxWidth="100%" alignItems="flex-start" spacing="1rem">
          <Stack maxWidth="100%" lineHeight="1rem" spacing="0.25rem">
            <Text lineHeight="inherit" variant="secondary" size="md">
              Видавництво
            </Text>
            <NavLink to={`/publishers/${publishingHouse}`}>
              <Text
                lineHeight="inherit"
                fontWeight={500}
                variant="link"
                size="md"
              >
                {publishingHouse}
              </Text>
            </NavLink>
          </Stack>
          <Stack lineHeight="1rem" spacing="0.25rem">
            <Text lineHeight="inherit" variant="secondary" size="md">
              Рік видання
            </Text>
            <Text
              lineHeight="inherit"
              fontWeight={500}
              variant="primary"
              size="md"
            >
              {releaseYear ?? 2023}
            </Text>
          </Stack>
          <Stack lineHeight="1rem" spacing="0.25rem">
            <Text lineHeight="inherit" variant="secondary" size="md">
              Оригінальна назва
            </Text>
            <Text
              lineHeight="inherit"
              fontWeight={500}
              variant="primary"
              size="md"
            >
              {originalName ?? "Оригінальна назва"}
            </Text>
          </Stack>
          <Stack lineHeight="1rem" spacing="0.25rem">
            <Text lineHeight="inherit" variant="secondary" size="md">
              Тип книги
            </Text>
            <Text
              lineHeight="inherit"
              fontWeight={500}
              variant="primary"
              size="md"
            >
              Електронна книга
            </Text>
          </Stack>
          <Stack lineHeight="1rem" spacing="0.25rem">
            <Text lineHeight="inherit" variant="secondary" size="md">
              Кількість сторінок
            </Text>
            <Text
              lineHeight="inherit"
              fontWeight={500}
              variant="primary"
              size="md"
            >
              {numberOfPages ?? 0}
            </Text>
          </Stack>
        </Stack>
        <Box>
          <Text
            marginBottom="0.75rem"
            lineHeight="1.5rem"
            fontWeight={400}
            size="lg"
          >
            Опис
          </Text>
          <Text lineHeight="1.25rem" size="md">
            {description}
          </Text>
        </Box>
      </Stack>
      <ToastContainer />
    </VStack>
  );
};

export default MobileBookDetailsSection;
