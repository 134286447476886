import { Box, Text } from "@chakra-ui/layout";
import { FunctionComponent, useEffect, useState } from "react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import PublisherList from "../../features/PublishersPage/components/PublisherList/PublisherList";
import LetterPagination from "../../features/PublishersPage/components/LetterPagination/LetterPagination";
import { usePublishers } from "../../features/HomePage/api/client";
interface PublishersPageProps {}

const PublishersPage: FunctionComponent<PublishersPageProps> = () => {
  const [letterFilter, setLetterFilter] = useState<string>("");
  const [letters, setLetters] = useState<string[]>([]);
  const { data: publisherList, isLoading, error } = usePublishers();

  const filteredPublisherList =
    letterFilter !== ""
      ? publisherList?.publishingHouses.filter((publisher) =>
          publisher.name.toUpperCase().startsWith(letterFilter)
        )
      : publisherList?.publishingHouses;

  const pickLetter = (letter: string) => {
    if (letterFilter === letter) {
      setLetterFilter("");
      return;
    }
    setLetterFilter(letter);
  };

  useEffect(() => {
    const firstLetters = Array.from(
      new Set(
        publisherList?.publishingHouses
          .map((publisher) => {
            return publisher.name.charAt(0).toUpperCase();
          })
          .sort()
      )
    );

    if (firstLetters) {
      setLetters(firstLetters);
    }
  }, [publisherList]);

  return (
    <Box
      paddingTop={{ base: "0rem", sm: "1.25rem", md: "1.75rem", xl:'2rem' ,'2xl': "2.5rem" }}
      paddingLeft={{
        base: "0.5rem",
        md: "1rem",
        xl: "2rem",
        "2xl": "4.75rem",
      }}
      paddingRight={{
        base: "0.75rem",
        md: "1.35rem",
        xl: "2.75rem",
        "2xl": "6.5rem",
      }}
    >
      <Breadcrumb
        breadcrumbItems={[
          { title: "Головна", path: "/" },
          { title: "Видавництва" },
        ]}
      />
      <Text
        marginTop={{ base: "0rem", sm: "1.25rem", md: "1.75rem", xl:'2rem' ,'2xl': "2.5rem" }}
        marginBottom={{
          base: "0.75rem",
          sm: "1rem",
          md: "1.5rem",
          xl: "2rem",
        }}
        variant="primary"
        fontWeight={600}
        line-height={{ base: "1rem", xl: "2.75rem" }}
        fontSize={{
          base: "1.75rem",
          sm: "1.5rem",
          md: "1,75rem",
          xl: "2.25rem",
        }}
      >
        Видавництва
      </Text>

      <Box marginBottom={{base:'1rem', sm:'2rem', xl:'3rem'}}>
        <LetterPagination
          letters={letters}
          activeLetter={letterFilter}
          handleClick={pickLetter}
        />
      </Box>

      <PublisherList publishers={filteredPublisherList} />
    </Box>
  );
};

export default PublishersPage;
