import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAzJDemC7kmx8VeDuMNG9RkRJYdDbssOWM",
  authDomain: "bookreader-new-prod.firebaseapp.com",
  projectId: "bookreader-new-prod",
  storageBucket: "bookreader-new-prod.appspot.com",
  messagingSenderId: "610012061757",
  appId: "1:610012061757:web:586f6c88c96c4ebfc04a54",
  measurementId: "G-NNMKGTZ55E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
