import { Flex, Text } from "@chakra-ui/react";

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <Flex
      width="100%"
      marginRight={{ base: "0.75rem", sm: "0rem" }}
      py={{ base: "1.5rem", xl: "2rem" }}
      borderRadius="1rem"
      backgroundColor="#FF726F"
      justifyContent="center"
    >
      <Text
        fontWeight={500}
        color={"white"}
        size={{ base: "lg", sm: "md", xl: "lg" }}
      >
        {message ? message : "Щось пішло не так!"}
      </Text>
    </Flex>
  );
};

export default ErrorMessage;
