import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { CategoryList } from "../../features/CategoriesPage/components/CategoryList/CategoryList";
import { BookList } from "../../features/CategoriesPage/components/BookList/BookList";
import { Suspense, useState } from "react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import { CategoryType } from "../../features/HomePage/api/types";
import { useCategories } from "../../features/HomePage/api/client";
import { hexToRgb } from "../../utilities/hexToRgb";
import Loader from "../../ui/components/Loader/Loader";
export const CategoriesPage: React.FC = () => {
  const { data: categoryList, isLoading, error } = useCategories();
  const [activeCategory, setActiveCategory] = useState<
    CategoryType | undefined
  >(categoryList?.categories[0]);

  const pickCategory = (id: string) => {
    setActiveCategory(
      categoryList?.categories.find(
        (category: CategoryType) => category.id === id
      ) || categoryList?.categories[0]
    );
  };

  const loadingBackground = hexToRgb(activeCategory?.colorCode ?? "");

  return (
    <>
      <Flex
        minHeight={{ base: "fit-content", sm: "100vh" }}
        position="relative"
        width="100%"
        alignItems="stretch"
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: "1rem", md: "3.66%" }}
      >
        <Box
          width={{ base: "100%", md: "auto" }}
          flexGrow={{ base: 0, sm: 1 }}
          paddingTop={{
            base: "0rem",
            sm: "1.25rem",
            md: "1.75rem",
            xl: "2.5rem",
          }}
          paddingLeft={{
            base: "0.5rem",
            md: "1rem",
            xl: "2rem",
            "2xl": "4.75rem",
          }}
        >
          <Breadcrumb
            breadcrumbItems={[
              { title: "Головна", path: "/" },
              { title: "Категорії" },
            ]}
          />
          <Box marginTop={{ base: "0.75rem", md: "1.75rem", xl: "2.5rem" }}>
            <Text
              marginBottom={{ base: "1rem", md: "1.75rem", xl: "2.5rem" }}
              as="h1"
              lineHeight="1"
              fontWeight={{ base: 600, sm: 400 }}
              fontSize={{
                base: "1.75rem",
                sm: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              variant="primary"
            >
              Категорії
            </Text>
            <Box marginRight={{ base: "-0.75rem", sm: "0rem" }}>
              <CategoryList
                categories={categoryList?.categories || []}
                activeCategory={
                  activeCategory || categoryList?.categories[0] || null
                }
                pickCategory={pickCategory}
              />
            </Box>
          </Box>
        </Box>
        <Suspense
          fallback={
            <Loader
              marginBottom={{ base: "-3.75rem", sm: "0" }}
              marginLeft={{ base: "-0.75rem", sm: "0rem" }}
              height={{ base: "50vh", sm: "auto" }}
              borderRadius={{
                base: "0rem",
                sm: "1rem",
                md: "1.5rem",
                xl: "2rem",
              }}
              backgroundColor={`rgba(${loadingBackground.r},${loadingBackground.g},${loadingBackground.b},0.1)`}
              flexDirection="column"
              width={{ base: "100vw", sm: "100%", md: "55.35% !important" }}
            />
          }
        >
          <BookList
            activeCategory={activeCategory || categoryList?.categories[0]}
          />
        </Suspense>
      </Flex>
    </>
  );
};
