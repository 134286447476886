import { useQuery } from "react-query";
import api from "../../../services/api/api";
import { PublisherResponseType } from "./types";

export const usePublisher = (publisherName: string) => {
    return useQuery(
      ['publisherByName', publisherName],
      () => {
        return api.get<string, PublisherResponseType>(`/publisherByName?publisherName=${publisherName}`);
      },
      {
        refetchOnWindowFocus: false,
        enabled: publisherName !== "",
      }
    );
  };