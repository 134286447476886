import { useQuery } from "react-query";
import api from "../../../services/api/api";
import { GenreResponseType } from "./types";
import { BooksResponseType } from "../../HomePage/api/types";
export const useGenres = () => {
  return useQuery(
    ["genres"],
    () => {
      return api.get<string, GenreResponseType>("/genres");
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useBooksByGenreAndCategory = (
  category: string,
  page: number,
  limit: number,
  genre: string | null,
) => {
  return useQuery(
    [genre, category, page, limit],
    () => {
      return api.get<string, BooksResponseType>(
        `/booksByCategory?genre=${genre}&category=${category}&page=${page}&limit=${limit}`
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: false,
      enabled: genre !== "" && category !== "",
    }
  );
};

