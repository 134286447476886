import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { mainTheme } from "./ui/themes/mainTheme";
import { Layout } from "./ui/layouts/DefaultLayout/Layout";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import { CategoriesPage } from "./pages/CategoriesPage/CategoriesPage";
import SelectedCategoryPage from "./pages/SelectedCategoryPage/SelectedCategoryPage";
import BookDetailsPage from "./pages/BookDetailsPage/BookDetailsPage";
import AuthorPage from "./pages/AuthorPage/AuthorPage";
import BooksPage from "./pages/BooksPage/BooksPage";
import PublishersPage from "./pages/PublishersPage/PublishersPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PublisherPage from "./pages/PublisherPage/PublisherPage";
import CataloguePage from "./pages/CataloguePage/CataloguePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ProtectedRoute from "./services/api/Routing/ProtectedRoute";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import PersonalInfo from "./features/ProfilePage/components/PresonalInfo/PersonalInfo";
import PurchasedBookList from "./features/ProfilePage/components/PurchaseBookList/PurchasedBookList";
import PasswordChange from "./features/ProfilePage/components/PasswordChange/PasswordChange";
import ProfileLayout from "./features/ProfilePage/components/ProfileLayout/ProfileLayout";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage/TermsAndConditionsPage";

const queryClient = new QueryClient();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={mainTheme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="categories">
            <Route index element={<CategoriesPage />} />
            <Route path=":name" element={<SelectedCategoryPage />} />
          </Route>
          <Route path="books">
            <Route path=":id" element={<BookDetailsPage />} />
            <Route
              path="bestsellers"
              element={<BooksPage title="Бестселери" type="bestsellerBooks" />}
            />
            <Route
              path="newcomings"
              element={
                <BooksPage title="Новинки в читанці" type="newcomingBooks" />
              }
            />
          </Route>
          <Route path="publishers">
            <Route index element={<PublishersPage />} />
            <Route path=":name" element={<PublisherPage />} />
          </Route>
          <Route path="authors">
            <Route path=":name" element={<AuthorPage />} />
          </Route>
          <Route path="catalogue" element={<CataloguePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          >
            <Route index element={<ProfileLayout />} />
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="my-books" element={<PurchasedBookList />} />
            <Route path="change-password" element={<PasswordChange />} />
          </Route>
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ChakraProvider>
  </QueryClientProvider>
);
